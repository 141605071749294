import React, { useState } from "react";
import { Auth } from "aws-amplify";

export default function Header(props) {

    return (
        <footer>
            <div className="d-flex flex-wrap align-items-center h_40">
                <div className="col-12 col-xl-4 res_center">
                    <span>© 2020 jonahventures</span>
                </div>
                <div className="col-12 col-xl-4">
                    <ul className="ft_links">
                        <li><a href="/terms-conditions" className="transition">Terms of Use</a></li>
                        <li><a href="/privacy-policy" className="transition">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}
