import React, { useState, useEffect } from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import { API } from "aws-amplify";

const { compose, withProps, withHandlers } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} = require("react-google-maps");
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

const MapWithAMarkerClusterer = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAwC20HBIVJ5trA0u8JLry7nCxhEUaLXUA&v=3.exp&libraries=geometry,drawing,places&callback=Function.prototype",
    loadingElement: <div style={{ height: 'calc(100vh - 56px)' }} />,
    containerElement: <div style={{ height: 'calc(100vh - 56px)' }} />,
    mapElement: <div style={{ height: 'calc(80vh - 56px)' }} />,
  }),
  withHandlers({
    onMarkerClustererClick: () => (markerClusterer) => {
    },
    onMarkerClick: () => (marker) => {
      window.location = `/user/sample-detail?sampleid=${marker.sampleId}&batchid=${marker.batchId}&referrer=alldata`
    }
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    defaultZoom={3}
    defaultCenter={props.markers[0] || { "lat": 40.7604910000000018044374883174896240234375, "lng": -73.956267999999994344761944375932216644287109375 }}
  >
    <MarkerClusterer
      onClick={props.onMarkerClustererClick}
      averageCenter
      enableRetinaIcons
      gridSize={60}
    >
      {props.markers.map(marker => (
        <Marker
          onClick={props.onMarkerClick.bind(this, marker)}
          key={marker.id}
          position={{ lat: (marker.lat + Math.random() / 10000), lng: (marker.lng + Math.random() / 1000000) }}
          slug={marker.slug}
        />
      ))}
    </MarkerClusterer>
  </GoogleMap>
);


export default function SampleDetails(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([])
  const [markers, setMarkers] = useState([]);
  let state = {
    selected: [],
  };

  async function loadAllData() {
    return Promise.all([loadTaxonomy(), loadGeos()]);
  }


  async function loadTaxonomy() {
    return props.httpWrapper("jv-portal-apis", 'GET', `/esv`, 2).then((taxArr) => {
      setSearchResults(taxArr.filter((it)=>it.RepSpecies).map((it) => {

        return {
          "label": (it.RepSpecies && it.RepSpecies.toUpperCase() !== 'NA') ? it.RepGenus + " " + it.RepSpecies + "/" + it.RepCommonName : it.RepGenus,
          "labelledItem": it.RepGenus + " " + it.RepSpecies + "/" + it.RepCommonName,
          //"label": it.RepGenus + " " + it.RepSpecies + "/" + it.RepCommonName,

        }
      }))
    }).catch((e) => {
      props.setIsLoading(false);

    })
  }

  async function loadGeos(taxonomy, caller) {
    taxonomy = (taxonomy && taxonomy[0] && taxonomy[0].labelledItem) || '';

    if (caller === 'typeahead' && !taxonomy) {
      return;
    }
    props.setIsLoading(true);
    return props.httpWrapper("jv-portal-apis", "GET", `/sample/geoinfo?taxonomy=${taxonomy}`, 2).then((geoArr) => {
      props.setIsLoading(false);
      setMarkers(geoArr.map((it) => {
        return {
          "lat": Number(it.Lat),
          "lng": Number(it.Long),
          "sampleId": it.SampleId,
          "batchId": it.BatchId
        }
      }));
    }).catch((e) => {
      props.setIsLoading(false);
    })


  }

  useEffect(() => {
    loadAllData();
  }, []);

  return (
    <section className="db_sec">
      <div className="container" >
        <div className="row">
          <div className="col-md-6">
            <h3 className="hm_headng">EXPLORE ALL DATA</h3>
          </div>
          <div className="col-md-6">
            <Typeahead
              id="basic-example"
              delay="1000"
              options={searchResults}
              placeholder="Search Species"
              onChange={(taxonomy) => loadGeos(taxonomy, 'typeahead')}
              style={{ "float": "right", "width": "375px", "padding": "10px" }}
            />
          </div>
        </div>
        <div className="db_content">
          <div className="tab-content">
            <MapWithAMarkerClusterer markers={markers} />
          </div>
        </div>
      </div>

    </section>
  );
}
