import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Helmet } from "react-helmet";


export default function Header(props) {
    const [isLoading, setIsLoading] = useState(false);

    async function handleLogout() {
        await Auth.signOut();
        props.userHasAuthenticated(false);
        props.setUserContext({});
        props.history.push("/login");
    }

    return (

        <header>
            <Helmet>
                <script src="/assets/js/user/function.js"></script>
            </Helmet>
            <div className="container1400">
                <div className="d-flex justify-content-between align-items-center h_100">
                    <div>
                        <a href="/" className="logo">
                            <img src="/assets/images/user-image/logo.png" alt="LOGO" />
                        </a>
                    </div>
                    <div>
                        <ul className="h-right transition">



                            {props.isAuthenticated ? (
                                <>

                                    <li>
                                        <a href="/" className="h-txt transition">
                                            {props.userContext.attributes.email}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/user/dashboard" className="h-btn transition">
                                            Dashboard
                             </a>
                                    </li><li>
                                        <a  onClick={handleLogout} className="h-btn transition">
                                            SIGNOUT
                        </a>
                                    </li>
                                </>) : (<>

                                    <li>
                                        <a href="/login" className="h-btn transition" data-target="#loginPopup">
                                            SIGNIN
                        </a>
                                    </li>
                                    <li>
                                        <a href="/register" className="h-btn transition">
                                            SIGNUP
                        </a>
                                    </li>
                                </>)
                            }

                        </ul>
                    </div>

                    <div className="tg_icon">
                        <div className="bar1"></div>
                        <div className="bar2"></div>
                        <div className="bar3"></div>
                    </div>

                </div>
            </div>
        </header>

    );
}
