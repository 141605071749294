import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import Footer from './Footer'
import Contactus from './Contactus'

export default function Home(props) {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <div>

            <div className="container-fluid bn-bg">
                <div className="container1400">
                    <div className="inner-banner">
                        <img src="./assets/images/user-image/privacy-policy-bg.jpg" alt="image" className="w-100" />
                        <div className="inner-banner-info">
                            <h3>TERMS & <span>CONDITIONS</span></h3>
                            <ul className="bread-crumb">
                                <li><a href="/"><i className="fas fa-home"></i> HOME</a></li>
                                <li><a href="/terms-conditions" className="active"> TERMS & CONDITIONS</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <section className="sec1Ab">
                <div className="container">
                    <h3 className="hm_headng">TERMS & CONDITIONS</h3>
                    <span className="web_txt">
                        Jonah Ventures is a company that collects and analyzes environmental samples for the purpose of answering ecological questions. Samples collected include fecal samples, dust, soil, honey, and water using sampling kits provide by Jonah Ventures or their own. Customers
                        collect samples and submit them to Jonah Ventures for analysis. Along with data obtained from samples, customer-provided information may be collected and stored by us. Customers may be able to create an account on a Jonah Ventures mobile application and/or website and may be asked to answer questions.
    </span>
                    <span className="web_txt">
                        If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern the relationship between Jonah Ventures LLC and the user in relation to this website. PLEASE READ THESE TERMS OF SERVICE CAREFULLY.  IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT USE THE SERVICE.
    </span>
                    <span className="web_txt">
                        The use of this website is subject to the following terms of use:
                    
                        The content of the pages of this website and any data returned is for your general information and use only. It is not meant to provide any legal or scientific utility. The website is subject to change without notice.
    </span>
                    <span className="web_txt">
                        In using the Service, you agree not to engage in any of the following activities: (a) any resale or commercial (non-personal) use of the Service or the Materials therein; (b) any use of automated means, including spiders, robots, crawlers, data mining tools, or the like to download data from the Service; (c) any attempt to gain unauthorized access to Jonah Ventures’ servers or computer systems or to engage in any activity that disrupts, diminishes the quality of, interferes with the performance of, or impairs the functionality of, any part of the Service.
    </span>
                    <span className="web_txt">
                        In using the Service, you agree not to engage in any of the following activities: (a) any resale or commercial (non-personal) use of the Service or the Materials therein; (b) any use of automated means, including spiders, robots, crawlers, data mining tools, or the like to download data from the Service; (c) any attempt to gain unauthorized access to Jonah Ventures’ servers or computer systems or to engage in any activity that disrupts, diminishes the quality of, interferes with the performance of, or impairs the functionality of, any part of the Service.
    </span>
                    <span className="web_txt">
                        Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
                
    </span>
                    <span className="web_txt">
                        Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.
                 
    </span>
                    <span className="web_txt">
                        This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and
                          conditions.
                  
                  
    </span>
                    <span className="web_txt">
                        Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.
    </span>
                    <span className="web_txt">
                        By submitting a sample to Jonah Ventures, you agree to, and hereby do, transfer and assign to the company all right, title, and interest in and to that sample and the information derived therefrom. You understand and agree that Jonah Ventures may use your samples and any data related thereto or derived therefrom for research and development purposes, including collaborative research with third parties. No personal information will be included in these activities. The user will have the option to make their de-identified data available or not available to the public. You understand that you will not receive any financial benefit from the company as a result of having your sample or any data related thereto or derived therefrom analyzed. You understand that by providing any sample, you acquire no rights in any research or commercial products that may be developed by the company or its collaborating partners.
    </span>
                    <span className="web_txt">
                        In the registering form of the data portal we ask the following personal information (PI): name, email address, telephone number, and mailing address. This information is required to uniquely identify samples and to facilitate communication between the company and the user. We will not sell or transfer your PI to third parties, but may transmit the PI to entities in the future affiliated with Jonah Ventures or as part of any merger, acquisition, or sale of assets.
    </span>
                    <span className="web_txt">
                        No refunds are guaranteed to be made available for used or unused kits purchased, or for any results that are considered inadequate by the user as a result of events that may have occurred outside of the control of the company. Upon request, Jonah Ventures may ship a replacement sample collection kit provided. The company may charge an additional fee in connection
                         with this replacement unless it is determined that the company is at fault for the non-usability of the collection kit due to damage or defect.
    </span>
                    <span className="web_txt">
                        In using this service, you agree to defend, indemnify and hold harmless Jonah Ventures
                         and its respective directors, employees, and agents, from and against any claims, damages, costs, liabilities, and expenses (including, but not limited to, reasonable attorneys’ fees) arising out of or related to the Service, including without limitation any actual or threatened suit, demand, or claim arising out of or relating to the user customer content, the information supplied to you by Jonah Ventures.
    </span>
                    <span className="web_txt">
                        Please contact us at legal@jonahventures.com with any questions regarding any of these terms.
                 
    </span>
                </div>
            </section>
            <div className="container">
                <section className="ft_bg">
                    <div className="row align-items-center">
                        <div className="col-12 col-xl-4">
                            <div className="ft_left">
                                <h3>Address</h3>
                                <div className="ft_info">
                                    <img src="./assets/images/user-image/marker.png" alt="image" />
                                    <span>5485 Conestoga Ct #210, Boulder CO 80301</span>
                                </div>
                                <div className="ft_info mt-3">
                                    <img src="./assets/images/user-image/msg.png" alt="image" />
                                    <span>info@jonahventures.com</span>
                                </div>
                                <div className="mt-3">
                                    <a className="tw_btn" href="https://twitter.com/jonahventures" target="_blank">twitter <img src="./assets/images/user-image/tw.png" alt="image" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-8">
                            <Contactus />
                        </div>
                    </div>
                </section>
            </div>

        </div>
    );
}

