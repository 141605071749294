import React, { useRef, useState, useEffect } from "react";
import Downloader from 'js-file-downloader';
import { DropdownButton, Dropdown, Alert } from 'react-bootstrap';
import { useFormFields } from "../libs/hooksLib";
import config from "../config";
const { v1: uuidv1 } = require('uuid');
const moment = require('moment');
const request = require('superagent');

export default function ExploreData(props) {
    const [batchArr, setBatchArr] = useState([]);
    const initFilterBy = "Show All"

    const [fields, handleFieldChange] = useFormFields({
        "filterText": ""
    });

    const [errors, setErrors] = useState({
        "filterText": "",
        "filterBy": ""
    });

    const [filterBy, setfilterBy] = useState(initFilterBy)

    async function loadBatchSamples() {
        let url = "";
        let filterVal = fields.filterText.trim();
        if (filterBy === 'User Name') {
            url = url + "clientid=" + filterVal.toLowerCase();
        } else {
            url = url + "clientid=" + props.userContext.attributes.email;
        }

        if (filterBy === 'Batch Id') {
            url = url + "&batchid=" + filterVal.toUpperCase();
        }

        if (filterBy === 'Sample Id') {
            url = url + "&sampleid=" + filterVal.toUpperCase();
        }

        return props.httpWrapper("jv-portal-apis", 'GET', `/batch?${url}`, 2)
    }

    async function onLoad() {
        try {
            props.setIsLoading(true);
            const batchesArr = await loadBatchSamples();
            props.setIsLoading(false);
            setBatchArr(batchesArr);
        } catch (e) {
            props.setIsLoading(false);
        }

    }
    useEffect(() => {
        onLoad();
    }, []);

    const onClickHandler = (event) => {
        const value = event.target.innerHTML;
        setfilterBy(value)
    }

    function validateForm(errors) {
        let valid = true;
        Object.keys(fields).forEach(
            (val) => handleChange({ "target": { "name": val, "value": fields[val] } })
        );
        Object.values(errors).forEach(
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    async function getFileUrl(url, auth) {
        return request
            .get(url)
            .set('Authorization', auth)
            .set('Accept', 'application/json')
            .then(res => {
                // res.body, res.headers, res.status
                return res.body.url
            })
            .catch(err => {
                // err.message, err.response
                console.log(err.message)
                if(err.message === 'Gateway Timeout')
                throw err;
                else
                return url
            });


    }


    function handleChange(event) {
        const { name, value } = event.target;
        //alert("event.target" + event.target.name + event.target.value)
        switch (name) {
            case 'filterText':
                errors.filterText =
                    (value.filterText
                        ? ''
                        : 'Enter value for the filter')
                break;
            case 'filterBy':
                errors.filterBy =
                    (value.filterBy
                        ? ''
                        : 'Enter Filter criteria')
                break;
            default:
                break;
        }
        handleFieldChange(event)
        setErrors(errors)
    }

    async function handleSubmit(event) {
        event.preventDefault();
        if (!validateForm(errors)) {
            //return;
        }
        onLoad();

    }
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function handleDownload(url, fileName) {
        let fileUrl = await getFileUrl(config.apiGateway.URL + url, props.userContext.idToken);
        console.log(fileUrl)
        let hdrs = [[
            { name: 'Accept', value: "text/csv" }
        ]]
        if (fileUrl === (config.apiGateway.URL + url)) {
            hdrs.push({ name: 'Authorization', value: props.userContext.idToken })

            return request
                .get(fileUrl)
                .set('Accept', 'text/csv')
                .set('Authorization', props.userContext.idToken)
                .then(response => {
                    // res.body, res.headers, res.status
                    console.log("response", response)
                    let blob = new Blob([response.text], { type: 'text/csv' });
                    let url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${fileName}`);
                    document.body.appendChild(link);
                    link.click();
                })

        } else {
            return request
                .get(fileUrl)
                .set('Accept', 'text/csv')
                .then(response => {
                    // res.body, res.headers, res.status
                    console.log("response", response)
                    let blob = new Blob([response.text], { type: 'text/csv' });
                    let url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${fileName}`);
                    document.body.appendChild(link);
                    link.click();
                })
        }
    }

    async function download(url, fileName, requestId) {
        let status = 'waiting';
        let iteration = 0;
        let resp;
        if (!requestId)
            url = url + "&requestId=" + uuidv1();
        props.setIsLoading(true);
        do {
            try {
                resp = await handleDownload(url, fileName, 0);
                status = 'complete';
            } catch (e) {
                await sleep(5000)
                console.log(e)
                iteration = iteration + 1
            }
        } while (status == 'waiting' && iteration < 50000)
        props.setIsLoading(false);
        return resp;
    }
    function returnRows(batchesArr) {
        let i = 0;
        return batchesArr.map((it) => {
            let link = "/user/sample-detail?sampleid=" + it.SampleId + "&batchid=" + it.BatchId;
            i++;
            return (
                <tr key={i}>
                    <td width="16%">{it.BatchId}</td>
                    <td width="12%">{moment.utc(it.DateReceivedTS).local().format('MM/DD/YYYY')}</td>
                    <td width="12%">{it.Status}</td>
                    <td width="12%">{it.TestId}</td>
                    <td width="12%"><a href="#" onClick={() => download("/batch/readdata?batchid=" + it.BatchId + "&testid=" + it.TestId + "&mode=download", it.BatchId + "-" + it.TestId + '-read-data.csv')}> <i className="fa fa-download" aria-hidden="true"></i> </a></td>
                    <td width="12%"><a href="#" onClick={() => download("/batch/esv?batchid=" + it.BatchId + "&testid=" + it.TestId + "&mode=download", it.BatchId + "-" + it.TestId + '-esv-data.csv')}><i className="fa fa-download" aria-hidden="true"></i></a></td>
                    <td width="12%"><a href="#" onClick={() => download("/batch/testmethods?batchid=" + it.BatchId + "&testid=" + it.TestId + "&mode=download", it.BatchId + "-" + it.TestId + '-testmethods.txt')}> <i className="fa fa-download" aria-hidden="true"></i></a> </td>
                    <td width="12%"><a href="#" onClick={() => download("/batch/samples?batchid=" + it.BatchId + "&mode=download", it.BatchId + '-samples.csv')}> <i className="fa fa-download" aria-hidden="true"></i></a> </td>
                </tr>
            )
        })

    }

    return (

        <section className="db_sec">
            <div className="container">
                <h3 className="hm_headng">EXPLORE YOUR DATA</h3>
                <div className="db_content">
                    <div className="profile_info mt-3">

                        <div className="row">

                            <div className="col-md-2">

                                <DropdownButton title={filterBy}>

                                    {
                                        filterBy === initFilterBy ? (<></>) : (<Dropdown.Item onClick={onClickHandler}>{initFilterBy}</Dropdown.Item >)

                                    }
                                    {
                                        (filterBy === "User Name" || props.userContext.attributes['custom:role'] !== 'admin') ? (<></>) : (<Dropdown.Item onClick={onClickHandler}>User Name</Dropdown.Item >)

                                    }
                                    {
                                        filterBy === "Batch Id" ? (<></>) : (<Dropdown.Item onClick={onClickHandler}>Batch Id</Dropdown.Item >)

                                    }
                                </DropdownButton>
                            </div>
                            <div className="col-md-10">

                                <div className="row">
                                    <div className="col-md-3">
                                        <input
                                            id="filterText"
                                            name="filterText"
                                            type='text'
                                            className="material-input"
                                            onChange={handleChange}
                                            value={fields.filterText}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <button bsStyle="default" style={{ "background-color": "white", "color": "black" }} className="btn btn-primary" onClick={handleSubmit}>Search</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane active" id="mysample">
                            <div className="profile_info mt-4">

                                {props.isLoading ? (<></>) : (
                                    (batchArr.length === 0) ? (
                                        <div>
                                            <h4 className="no-data-found">{props.userContext.attributes['custom:role'] === 'admin' ? 'Admin users must use filter criteria' : 'No report submitted yet'}</h4>
                                        </div>
                                    ) : (<div className="table-responsive">
                                        <table className="table db_table_th" cellPadding="0" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th width="16%">BatchID </th>
                                                    <th width="12%">Last Updated</th>
                                                    <th width="12%">Status</th>
                                                    <th width="12%">Test</th>
                                                    <th width="12%">Read Data</th>
                                                    <th width="12%">ESV Data</th>
                                                    <th width="12%">Methods</th>
                                                    <th width="12%">Samples</th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <table className="table db_table_td mt-4">
                                            <tbody>
                                                {returnRows(batchArr)}
                                            </tbody>
                                        </table>
                                    </div>)


                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

