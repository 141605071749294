import React, { useRef, useState, useEffect } from "react";

export default function ExploreData(props) {
    const [batchArr, setBatchArr] = useState([]);

    useEffect(() => {
        async function loadBatchSamples() {
            return props.httpWrapper("jv-portal-apis", 'GET', `/batch?clientid=${props.userContext.attributes.email}`, 2)
        }

        async function onLoad() {
            try {
                const batchesArr = await loadBatchSamples();
                props.setIsLoading(false);
                setBatchArr(batchesArr);
            } catch (e) {
                props.setIsLoading(false);
            }

        }
        props.setIsLoading(true);
        onLoad();
    }, []);


    function returnRows(batchesArr) {
        let i = 0;
        return batchesArr.map((it) => {
            let link = "/user/sample-detail?sampleid=" + it.SampleId + "&batchid=" + it.BatchId;
            i++;
            return (
                <tr key={i}>
                    <td>Fish</td>
                    <td>ESV_000024</td>
                    <td>Eukaryota</td>
                    <td>Chordata</td>
                    <td></td>
                    <td>Cypriniformes</td>
                    <td>Cyprinidae</td>
                    <td>Cyprinus</td>
                    <td>carpio</td>
                    <td>100</td>
                    <td>CACCGCGGTTAGACGAGAGGCCCTAGTTGATATTACAACGGCGTAAAGGGTGGTTAAGGATAAACAAAAATAAAGTCAAATGGCCCCTTGGCCGTCATACGCTTCTAGGAGTCCGAAGCCCTAATACGAAAGTAACTTTAATAAACCCACCTGACCCCACGAAAGCTGAGAAA</td>
                </tr>
            )
        })

    }

    return (

        <section className="db_sec">
            <div className="container">
                <h3 className="hm_headng">Read Data</h3>
                <div className="db_content">
                    <div className="tab-content">
                        <div className="tab-pane active" id="mysample">
                            <div className="profile_info mt-4">

                                {props.isLoading ? (<></>) : (
                                    (batchArr.length === 0) ? (
                                        <div>
                                            <h4 className="no-data-found">No report submitted yet</h4>
                                        </div>
                                    ) : (<div className="table-responsive">
                                        <a href="http://jonah.flexsin.org/corporate-user/explore-data" class="btn btn-primary">Back</a>
                                        <table className="table db_table_th" cellPadding="0" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th width="10%">TestId </th>
                                                    <th width="10%">ESV_ID </th>
                                                    <th width="10%">Kingdom</th>
                                                    <th width="10%">Phylum</th>
                                                    <th width="10%">Class</th>
                                                    <th width="10%">Order</th>
                                                    <th width="10%">Family</th>
                                                    <th width="10%">Genus</th>
                                                    <th width="10%">Species</th>
                                                    <th width="10%">Perc Match</th>
                                                    <th width="10%">Sequence</th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <table className="table db_table_td mt-4">
                                            <tbody>
                                                {returnRows(batchArr)}
                                            </tbody>
                                        </table>
                                    </div>)


                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

