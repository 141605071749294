import React, { useState } from "react";

import "./Login.css";

export default function Dashboard(props) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <section className="db_sec">
      <div className="container">
        <h3 className="hm_headng">DASHBOARD</h3>
        <div className="welcome-bx">
          <div className="row">

            <div className={props.userContext && props.userContext.attributes && props.userContext.attributes['custom:type'] === 'corporate'  ? "col-12 col-md-6" : "col-12 col-md-6"}>
              <div className="db-bx-info text-center">
                <div className="ic">
                  <img src="/assets/images/user-image/db-icon-1.png" alt="image" />
                  <span className="d-block mt-3">{( props.userContext && props.userContext.attributes && props.userContext.attributes['custom:role'] === 'admin') ? "Citizen Scientist Data": "Sequencing"}</span>
                </div>
                <div className="db-lg-btn">
                  <a href={props.userContext && props.userContext.attributes && props.userContext.attributes['custom:type'] === 'corporate' ? "/corporate-user/explore-data" : "/user/explore-data"} className="transition">EXPLORE</a>
                </div>
              </div>
            </div>

            {props.userContext && props.userContext.attributes && props.userContext.attributes['custom:role'] === 'admin' ? (
            <div className="col-12 col-md-6">
              <div className="db-bx-info text-center">
                <div className="ic">
                  <img src="/assets/images/user-image/db-icon-1.png" alt="image" />
                  <span className="d-block mt-3">Research / Corporate Data</span>
                </div>
                <div className="db-lg-btn">
                  <a href="/corporate-user/explore-data" className="transition">EXPLORE</a>
                </div>
              </div>             
            </div>) : (<></>)}

            {(props.userContext && props.userContext.attributes && (props.userContext.attributes['custom:role'] === 'admin' || props.userContext.attributes['custom:type'] === 'corporate'))? (
            <div className="col-12 col-md-6">
              <div className="db-bx-info text-center">
                <div className="ic">
                  <img src="/assets/images/user-image/db-icon-1.png" alt="image" />
                  <span className="d-block mt-3">QPCR Data</span>
                </div>
                <div className="db-lg-btn">
                  <a href="/corporate-user/explore-qpcr-data" className="transition">EXPLORE</a>
                </div>
              </div>             
            </div>) : (<></>)}


            {props.userContext && props.userContext.attributes && props.userContext.attributes['custom:type'] === 'corporate' ? (<></>) : (
              <div className="col-12 col-md-6">
                <div className="db-bx-info text-center">
                  <div className="ic">
                    <img src="/assets/images/user-image/db-icon-1.png" alt="image" />
                    <span className="d-block mt-3">Public Data</span>
                  </div>
                  <div className="db-lg-btn">
                    <a href="/user/explore-all-data" className="transition">EXPLORE</a>
                  </div>
                </div>
              </div>)}

          </div>
        </div>
      </div>
    </section>
  );
}
