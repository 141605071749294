import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useFormFields } from "../libs/hooksLib";

export default function Login(props) {
  const [fields, handleFieldChange] = useFormFields({
    email: ((props.userContext && props.userContext.UnauthUserCtx && props.userContext.UnauthUserCtx.email) || ''),
    password: "",
    confirmpasswd: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmpasswd: ""
  });

  let userCtx = props.userContext && props.userContext.UnauthUserCtx && props.userContext.UnauthUserCtx.user;

  if (!userCtx) {
    props.history.push("/login");
  }
  const [message, setMessage] = useState('');


  const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

  function handleChange(event) {
    const { name, value } = event.target;
    switch (name) {
      case 'email':
        errors.email =
          validEmailRegex.test(value)
            ? ''
            : 'Email is not valid';
        break;
      case 'password':
        errors.password =
          value.length < 9
            ? 'Password must be 9 characters long'
            : '';
        break;
      case 'confirmpasswd':
        errors.confirmpasswd =
          value.length < 9
            ? 'Password must be 9 characters long'
            : '';
        break;
      default:
        break;
    }
    handleFieldChange(event)
    setErrors(errors)
  }

  function validateForm(errors) {
    let valid = true;
    Object.keys(fields).forEach(
      (val) => handleChange({ "target": { "name": val, "value": fields[val] } })
    );
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    if (valid) {
      if (fields.password !== fields.confirmpasswd) {
        valid = false;
        errors.confirmpasswd = 'Please enter the same value again.'
      }
    }
    return valid;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (!validateForm(errors)) {
      return;
    }

    props.setIsLoading(true);

    try {

      if (userCtx.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(
          userCtx,               // the Cognito User Object
          fields.password,       // the new password
          // userCtx.challengeParam.userAttributes
        ).then(async (user) => {
          await Auth.signIn(fields.email.toLowerCase(), fields.password).then((data) => {
            if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
              props.setUserContext({ "UnauthUserCtx": { "email": fields.email.toLowerCase(), "user": data } });
              props.history.push("/new-password");
              return;
            }
            props.setUserContext({ "attributes": data.attributes, "idToken": data.signInUserSession.idToken.jwtToken });
            props.userHasAuthenticated(true);
            if (!props.history.location.search)
              props.history.push("/user/dashboard");
          }).catch(err => {
            if (err.message === 'User is not confirmed.')
              props.history.push("/verify-user");
            else
              setMessage(err.message);
          });
        }).catch(err => {
          if (err.message === 'User is not confirmed.') {
            props.history.push("/verify-user");
            return;
          }
          else
            setMessage(err.message);
        });
      }
      props.setIsLoading(false);

    } catch (e) {
      alert(e.message);
      props.setIsLoading(false);
    }
  }

  function loadPasswordForm() {
    return (
      <div className="card-body">
        <div className="modal_txt text-right">
          <h3>LOGIN</h3>

        </div>
        <div className="modal_left">
          <h3 className="card-title">Change Password</h3>
          <span className="card-text">Please provide a new password</span>
          {message ? (<p style={{
            padding: "5px",
            "margin-top": "20px",
            "margin-bottom": "10px",
            "font-size": "14px",
            width: "100%",
            background: "#F5F5F5",
            border: "2px solid #D64958",
            color: "#D64958"
          }}> {message} </p>) : (<></>)}
          <form id="newpwd" onSubmit={handleSubmit} method="post" className="mt-5">
            <div className="form-group">
              <input type="text" onChange={handleChange} name="email" value={fields.email} className="material-input" id="email" placeholder="Email Address" style={{ "width": "50%" }} />
              <label className="form__label">Email Address</label>
              {errors.email.length > 0 &&
                <label id="email-error" class="error jquery-validation-error small form-text invalid-feedback" for="password" style={{ display: (errors.email ? "block" : "none") }}>{errors.email}</label>}
            </div>
            <div className="form-group">
              <input type="password" onChange={handleChange} id="password" className="material-input" name="password" placeholder="Password" style={{ "width": "50%" }} />
              <label className="form__label">Password</label>
              {errors.password.length > 0 &&
                <label id="password-error" class="error jquery-validation-error small form-text invalid-feedback" for="password" style={{ display: (errors.password ? "block" : "none") }}>{errors.password}</label>}
            </div>
            <div className="form-group">
              <input type="password" onChange={handleChange} id="confirmpasswd" name="confirmed" className="material-input" placeholder="Confirm Password" style={{ "width": "50%" }} />
              <label className="form__label">Confirm Password</label>
              {errors.confirmpasswd.length > 0 &&
                <label id="confirm-error" class="error jquery-validation-error small form-text invalid-feedback" for="confpassword" style={{ display: (errors.confirmpasswd ? "block" : "none") }}>{errors.confirmpasswd}</label>}
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary transition">SUBMIT</button>
            </div>
          </form>
        </div>
      </div>)
  }

  return (
    <div className="container">
      <section className="op_p" style={{ "border": "none", "background": "transparent" }}>
        <div className="card" style={{ "border": "light", "background": "transparent" }}>
          {loadPasswordForm()},
        </div></section></div>
  );
}
