import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useFormFields } from "../libs/hooksLib";
import { Helmet } from "react-helmet";

export default function Login(props) {
    const [fields, handleFieldChange] = useFormFields({
        email: ((props.userContext && props.userContext.UnauthUserCtx && props.userContext.UnauthUserCtx.email) || ''),
        pin: "",
        password: "",
        confirmpasswd: ""
    });
    const [errors, setErrors] = useState({
        email: "",
        password: "",
        pin: "",
        confirmpasswd: ""
    });
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState('ForgotPassword');

    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    function handleChange(event) {
        const { name, value } = event.target;
        switch (name) {
            case 'email':
                errors.email =
                    validEmailRegex.test(value)
                        ? ''
                        : 'Email is not valid';
                break;
            case 'password':
                errors.password =
                    value.length < 9
                        ? 'Password must be 9 characters long'
                        : '';
                break;
            case 'confirmpasswd':
                errors.confirmpasswd =
                    value.length < 9
                        ? 'Password must be 9 characters long'
                        : '';
                break;
            case 'pin':
                errors.pin =
                    value.length === 0
                        ? 'Please enter Verification Code'
                        : '';
                break;
            case 'firstname':
                errors.firstname =
                    value.length === 0
                        ? 'First Name is required'
                        : '';
                break;
            case 'lastname':
                errors.lastname =
                    value.length === 0
                        ? 'Last Name is required'
                        : '';
                break;
            case 'phoneno':
                errors.phoneno =
                    value.length < 10
                        ? 'Phone number must have 10 digits'
                        : '';
                break;
            default:
                break;
        }
        handleFieldChange(event)
        setErrors(errors)
    }

    function validateForm(errors, obj) {
        let valid = true;
        Object.keys(obj || fields).forEach(
            (val) => handleChange({ "target": { "name": val, "value": fields[val] } })
        );
        Object.values(errors).forEach(
            (val) => val.length > 0 && (valid = false)
        );
        if (valid) {
            if (fields.password !== fields.confirmpasswd) {
                valid = false;
                errors.confirmpasswd = 'Please enter the same value again.'
            }
        }

        return valid;

    }

    async function handleForgotPassword(event) {
        event.preventDefault();
        if (!validateForm(errors, { "email": "" })) {
            return;
        }

        props.setIsLoading(true);

        try {
            Auth.forgotPassword(fields.email.toLowerCase())
                .then(data => {
                    setMode('ResetPassword');
                }).catch(err => {
                    setMessage(err.message);
                });

        } catch (e) {
        }
        props.setIsLoading(false);
    }

    async function handleResetPassword(event) {
        event.preventDefault();
        if (!validateForm(errors)) {
            return;
        }
        props.setIsLoading(true);
        try {
            Auth.forgotPasswordSubmit(fields.email.toLowerCase(), fields['pin'], fields["password"])
                .then(data => {
                    props.history.push("/login");
                }).catch(err => {
                    setMessage(err.message);
                });

        } catch (e) {
            alert(e.message);
        }
        props.setIsLoading(false);
    }

    function loadForgotPasswordForm() {
        return (<div class="card-body">
            <Helmet>
                <script src="/assets/js/user/jquery.validate.min.js"></script>
                <script src="/assets/js/user/form-validation.js"></script>
            </Helmet>
            <div class="modal_txt text-right">
                <h3>LOGIN</h3>

            </div>
            <div class="modal_left">
                <h3 class="card-title">Forgot Password</h3>
                <span class="card-text">Enter Your Email Below</span>
                {message ? (<p style={{
                    padding: "5px",
                    "margin-top": "20px",
                    "margin-bottom": "10px",
                    "font-size": "14px",
                    width: "100%",
                    background: "#F5F5F5",
                    border: "2px solid #D64958",
                    color: "#D64958"
                }}> {message} </p>) : (<></>)}
                <form id="forgotpwd" onSubmit={handleForgotPassword} method="post" class="mt-5">
                    <div class="form-group">
                        <input type="text" onChange={handleChange} name="email" value={fields.email} class="material-input" id="email" placeholder="Email Address" style={{ "width": "50%" }} />
                        <label for="email" class="form__label">Email Address</label>
                        {errors.email.length > 0 &&
                            <label id="email-error" class="error jquery-validation-error small form-text invalid-feedback" for="pin" style={{ display: (errors.email ? "block" : "none") }}>{errors.email}</label>}
                    </div>

                    <div class="form-group">
                        <button type="submit" class="btn btn-primary transition">SUBMIT</button>
                    </div>
                </form>
            </div>
        </div>)
    }

    function loadResetPasswordForm() {
        return (<div class="card-body">
            <div class="modal_txt text-right">
                <h3>LOGIN</h3>

            </div>
            <div class="modal_left">
                <h3 class="card-title">Reset Password</h3>
                <span class="card-text">Verify Code sent in email</span>
                {message ? (<p style={{
                    padding: "5px",
                    "margin-top": "20px",
                    "margin-bottom": "10px",
                    "font-size": "14px",
                    width: "100%",
                    background: "#F5F5F5",
                    border: "2px solid #D64958",
                    color: "#D64958"
                }}> {message} </p>) : (<></>)}
                <form id="handleresetform" onSubmit={handleResetPassword} method="post" class="mt-5">
                    <div class="form-group">

                        <input type="text" onChange={handleChange} id="pin" name="pin" value={fields.pin} class="material-input" placeholder="Pin" />
                        <label for="pin" class="form__label">Verification Code</label>
                        {errors.pin.length > 0 &&
                            <label id="email-error" class="error jquery-validation-error small form-text invalid-feedback" for="pin" style={{ display: (errors.pin ? "block" : "none") }}>{errors.pin}</label>}
                    </div>
                    <div class="form-group">

                        <input type="password" onChange={handleChange} id="password" name="password" value={fields.password} class="material-input" placeholder="Password" />
                        <label for="password" class="form__label">Password</label>
                        {errors.password.length > 0 &&
                            <label id="email-error" class="error jquery-validation-error small form-text invalid-feedback" for="password" style={{ display: (errors.password ? "block" : "none") }}>{errors.password}</label>}
                    </div>
                    <div class="form-group">
                        <input type="password" onChange={handleChange} id="confirmpasswd" name="confirmed" value={fields.confirmpasswd} class="material-input" placeholder="Confirm Password" />
                        <label for="confirmpasswd" class="form__label">Confirm Password</label>
                        {errors.confirmpasswd.length > 0 &&
                            <label id="email-error" class="error jquery-validation-error small form-text invalid-feedback" for="password" style={{ display: (errors.confirmpasswd ? "block" : "none") }}>{errors.confirmpasswd}</label>}
                    </div>

                    <div class="form-group">
                        <button type="submit" class="btn btn-primary transition">SUBMIT</button>
                    </div>
                </form>
            </div>
        </div>)
    }

    return (
        <div class="container">
            <section class="op_p" style={{ "border": "none", "background": "transparent" }}>
                <div class="card" style={{ "border": "light", "background": "transparent" }}>
                    {mode === 'ForgotPassword' ? loadForgotPasswordForm() : loadResetPasswordForm()}
                </div></section></div>
    );
}
