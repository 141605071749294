import React, { useRef, useState, useEffect } from "react";
import { DropdownButton, Dropdown, Alert } from 'react-bootstrap';
import { useFormFields } from "../libs/hooksLib";
const moment = require('moment');

export default function ExploreData(props) {
    const [batchArr, setBatchArr] = useState([]);
    const initFilterBy = "Show All"

    const [fields, handleFieldChange] = useFormFields({
        "filterText": ""
    });

    const [filterBy, setfilterBy] = useState(initFilterBy)

    const [errors, setErrors] = useState({
        "filterText": "",
        "filterBy": ""
    });

    async function loadBatchSamples() {
        let url = "";
        let filterVal = fields.filterText.trim();
        if (filterBy === 'User Name') {
            url = url + "clientid=" + filterVal.toLowerCase();
        } else {
            url = url + "clientid=" + props.userContext.attributes.email;
        }

        if (filterBy === 'Batch Id') {
            url = url + "&batchid=" + filterVal.toUpperCase();
        }

        if (filterBy === 'Sample Id') {
            url = url + "&sampleid=" + filterVal.toUpperCase();
        }

        return props.httpWrapper("jv-portal-apis", 'GET', `/batchsamples?${url}`, 2);
    }

    async function onLoad() {
        try {
            props.setIsLoading(true);
            const batchesArr = await loadBatchSamples();
            props.setIsLoading(false);
            setBatchArr(batchesArr);
        } catch (e) {
            props.setIsLoading(false);
        }

    }
    useEffect(() => {
        onLoad();
    }, []);

    const onClickHandler = (event) => {
        const value = event.target.innerHTML;
        setfilterBy(value)
    }

    function validateForm(errors) {
        let valid = true;
        Object.keys(fields).forEach(
            (val) => handleChange({ "target": { "name": val, "value": fields[val] } })
        );
        Object.values(errors).forEach(
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    function handleChange(event) {
        const { name, value } = event.target;
        //alert("event.target" + event.target.name + event.target.value)
        switch (name) {
            case 'filterText':
                errors.filterText =
                    (value.filterText
                        ? ''
                        : 'Enter value for the filter')
                break;
            case 'filterBy':
                errors.filterBy =
                    (value.filterBy
                        ? ''
                        : 'Enter Filter criteria')
                break;
            default:
                break;
        }
        handleFieldChange(event)
        setErrors(errors)
    }

    async function handleSubmit(event) {
        event.preventDefault();
        if (!validateForm(errors)) {
            //return;
        }
        onLoad();

    }

    function returnRows(batchesArr) {
        let i = 0;
        return batchesArr.map((it) => {
            let link = "/user/sample-detail?sampleid=" + it.SampleId + "&batchid=" + it.BatchId;
            i++;
            return (
                <tr key={i}>
                    <td width="20%">{it.SampleId}</td>
                    <td width="20%">{moment.utc(it.CaptureTimeTs).local().format('MM/DD/YYYY')}</td>
                    <td width="20%">{moment.utc(it.CaptureTimeTs).local().format('HH:mm:ss')}</td>
                    <td width="20%">{it.SiteName}</td>
                    <td width="20%"><a href={link}><button className="btn btn-primary transition">Explore</button></a></td>
                </tr>
            )
        })

    }

    return (

        <section className="db_sec">
            <div className="container">
                <h3 className="hm_headng">EXPLORE YOUR DATA</h3>

                <div className="db_content">
                    <div className="profile_info mt-3">

                        <div className="row">

                            <div className="col-md-2">

                                <DropdownButton title={filterBy}>

                                    {
                                        filterBy === initFilterBy ? (<></>) : (<Dropdown.Item onClick={onClickHandler}>{initFilterBy}</Dropdown.Item >)

                                    }
                                    {
                                        (filterBy === "User Name" || props.userContext.attributes['custom:role'] !== 'admin') ? (<></>) : (<Dropdown.Item onClick={onClickHandler}>User Name</Dropdown.Item >)

                                    }
                                    {
                                        filterBy === "Sample Id" ? (<></>) : (<Dropdown.Item onClick={onClickHandler}>Sample Id</Dropdown.Item >)

                                    }

                                </DropdownButton>
                            </div>
                            <div className="col-md-10">

                                <div className="row">
                                    <div className="col-md-3">
                                        <input
                                            id="filterText"
                                            name="filterText"
                                            type='text'
                                            className="material-input"
                                            onChange={handleChange}
                                            value={fields.filterText}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <button bsStyle="default" style={{ "background-color": "white", "color": "black" }} className="btn btn-primary" onClick={handleSubmit}>Search</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane active" id="mysample">
                            <div className="profile_info mt-4">

                                {props.isLoading ? (<></>) : (
                                    (batchArr.length === 0) ? (
                                        <div>
                                            <h4 className="no-data-found">{props.userContext.attributes['custom:role'] === 'admin' ? 'Admin users must use filter criteria' : 'No report submitted yet'}</h4>
                                        </div>
                                    ) : (<div className="table-responsive">
                                        <table className="table db_table_th" cellPadding="0" cellSpacing="0">
                                            <thead style={{ "bgcolor": "red" }}>
                                                <tr>
                                                    <th width="20%">
                                                        Sample Id
                                        </th>
                                                    <th width="20%">
                                                        Date Collected
                                        </th>
                                                    <th width="20%">
                                                        Time Colllected
                                        </th>
                                                    <th width="20%">
                                                        User Site Name
                                        </th>
                                                    <th width="20%">
                                                        &nbsp;
                                        </th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <table className="table db_table_td mt-4">
                                            <tbody>
                                                {returnRows(batchArr)}
                                            </tbody>
                                        </table>
                                    </div>)


                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

