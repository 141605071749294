import { API } from "aws-amplify";
import React, { useRef, useState, useEffect } from "react";
import qs from "qs";

export default function ExploreData(props) {
    const [batchArr, setBatchArr] = useState([]);
    const [sampleArr, setSampleArr] = useState([]);

    useEffect(() => {
        async function loadBatchSamples() {
            return props.httpWrapper("jv-portal-apis", 'GET', `/batch/esv?batchid=${props.match.params["0"]}&testid=${qs.parse(props.location.search, { ignoreQueryPrefix: true }).testid}`, 2)
        }

        async function onLoad() {
            try {
                console.log(JSON.stringify(props))
                const res = await loadBatchSamples();
                props.setIsLoading(false);
                setBatchArr(res);
            } catch (e) {
                props.setIsLoading(false);
            }

        }
        props.setIsLoading(true);
        onLoad();
    }, []);

    function returnHeader(sampleArr) {
        return (
            <tr>
                <th>TestId </th>
                <th>ESV_ID </th>
                <th >Kingdom</th>
                <th>Phylum</th>
                <th>Class</th>
                <th>Order</th>
                <th>Family</th>
                <th>Genus</th>
                <th>Species</th>
                <th>Perc Match</th>
            </tr>

        )

    }

    function returnRows(batchesArr) {
        let i = 0;
        return batchesArr.map((it) => {
            let link = "/user/sample-detail?sampleid=" + it.SampleId + "&batchid=" + it.BatchId;
            i++;
            return (
                <tr key={i}>
                    <td>{it.TestId}</td>
                    <td>{it.ESVId}</td>
                    <td>{it.Kingdom}</td>
                    <td>{it.Phylum}</td>
                    <td>{it.Class}</td>
                    <td>{it.Order}</td>
                    <td>{it.Family}</td>
                    <td>{it.Genus}</td>
                    <td>{it.Species}</td>
                    <td>{it.PercMatch}</td>
                </tr>
            )
        })

    }

    return (

        <section className="db_sec">
            <div className="container">
                <h3 className="hm_headng">ESV Data</h3>
                <div className="db_content">
                    <div className="tab-content">
                        <div className="tab-pane active" id="mysample">
                            <div className="profile_info mt-4">
                                <a href="/corporate-user/explore-data" class="btn btn-primary">Back</a>
                                <br /><br />
                                {props.isLoading ? (<></>) : (
                                    (batchArr.length === 0) ? (
                                        <div>
                                            <h4 className="no-data-found">No report submitted yet</h4>
                                        </div>
                                    ) : (<div className="table-responsive" style={{
                                        "overflow": "auto"
                                    }}>
                                        <table className="table db_table_th" cellPadding="0" cellSpacing="0">
                                            <thead>
                                                {returnHeader(sampleArr)}
                                            </thead>

                                            <tbody>
                                                {returnRows(batchArr)}
                                            </tbody>
                                        </table>
                                    </div>)


                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

