import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useFormFields } from "../libs/hooksLib";


export default function Login(props) {
    const [fields, handleFieldChange] = useFormFields({
        pin: "",
        email: ((props.userContext && props.userContext.UnauthUserCtx && props.userContext.UnauthUserCtx.email) || ''),
    });

    const [errors, setErrors] = useState({
        email: "",
        pin: ""
    });


    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    function handleChange(event) {
        const { name, value } = event.target;
        switch (name) {
            case 'email':
                errors.email =
                    validEmailRegex.test(value)
                        ? ''
                        : 'Email is not valid';
                break;
            case 'pin':
                errors.pin =
                    value.length === 0
                        ? 'Please enter Verification Code'
                        : '';
                break;
            default:
                break;
        }
        handleFieldChange(event)
        setErrors(errors)
    }

    function validateForm(errors) {
        let valid = true;
        Object.keys(fields).forEach(
            (val) => handleChange({ "target": { "name": val, "value": fields[val] } })
        );
        Object.values(errors).forEach(
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }


    const [message, setMessage] = useState('');

    function verifyPinForm() {
        return (<>
            <div className="modal_txt text-right">
                <h3>SIGNUP</h3>

            </div>
            <div className="modal_left mobile-overlow">
                <h3 className="title">Signup Verification</h3>
                <span className="card-text">Please enter Verification Code from the email</span>
                <div />

                {message ? (<p style={{
                    padding: "5px",
                    "margin-top": "20px",
                    "margin-bottom": "10px",
                    "font-size": "14px",
                    width: "100%",
                    background: "#F5F5F5",
                    border: "2px solid #D64958",
                    color: "#D64958"
                }}> {message} </p>) : (<></>)}
                <form className="mt-5" id="signup_form" method="post" onSubmit={handleVerifyPin}>

                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="form-group">
                                <input type="text" onChange={handleChange} id="email" value={fields.email} className="material-input" placeholder="Email Address" name="email_address" />
                                <label for="email_address" className="form__label">Email Address</label>
                                {errors.email.length > 0 &&
                                    <label id="email-error" class="error jquery-validation-error small form-text invalid-feedback" for="password" style={{ display: (errors.email ? "block" : "none") }}>{errors.email}</label>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="form-group">
                                <input type="text" onChange={handleChange} id="pin" name="pin" className="material-input" placeholder="Pin" />
                                <label for="pin" className="form__label">Verification Code</label>
                                {errors.pin.length > 0 &&
                                    <label id="email-error" class="error jquery-validation-error small form-text invalid-feedback" for="password" style={{ display: (errors.pin ? "block" : "none") }}>{errors.pin}</label>}
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="form-group">
                                <button className="btn btn-primary transition" onClick={resendPin} >Resend Code</button>

                            </div>
                        </div>
                    </div>


                    <div className="form-group">
                        <button type="submit" className="btn btn-primary transition" >SUBMIT</button>
                    </div>

                </form>
            </div></>)
    }

    async function resendPin(event) {
        event.preventDefault();
        setMessage('');
        props.setIsLoading(true);
        Auth.resendSignUp(fields.email.toLowerCase()).then(data => {
            // alert(JSON.stringify(data))
        }).catch(err => {
            setMessage(err.message);
        });
        props.setIsLoading(false);


    }

    async function handleVerifyPin(event) {
        event.preventDefault();
        if (!validateForm(errors)) {
            return;
        }
        setMessage('');
        props.setIsLoading(true);
        Auth.confirmSignUp(fields.email.toLowerCase(), fields['pin'], {
            // Optional. Force user confirmation irrespective of existing alias. By default set to True.
            forceAliasCreation: true
        }).then(data => {
            props.history.push("/login");
        }).catch(err => {
            if (err.message === 'User cannot be confirmed. Current status is CONFIRMED') {
                props.history.push("/login");
            } else
                setMessage(err.message);
        });
        props.setIsLoading(false);

    }

    return (
        <div className="container">
            <section className="op_p" style={{ "border": "none", "background": "transparent" }}>
                <div className="card" style={{ "border": "light", "background": "transparent" }}>
                    <div className="card-body">
                        {verifyPinForm()}
                    </div>
                </div></section></div>
    );
}
