import React, { useState } from "react";
import { Helmet } from "react-helmet";

export default function Header(props) {

    return (
        <div className="ft_right">
            <Helmet>
                <script src="/assets/js/user/jquery.validate.min.js"></script>
                <script src="/assets/js/user/form-validation.js"></script>
            </Helmet>
            <h3 className="mb-3">Questions?</h3>
            <div className="mt-3">
                <form id="contact_us" method="post" action="contact-us">
                    <input type="hidden" name="_token" value="jG52aPEQ5dzVhufw0pqKn3b2AhpqeDjRJFk64W8Q" />
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="form-group">
                                <input type="text" id="first_name" className="transition material-input-fff" placeholder="First Name" name="first_name" value="" />
                                <label for="firstname" className="form__label__2">First Name</label>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="form-group">
                                <input type="text" id="last_name" className="  material-input-fff transition" name="last_name" placeholder="Last Name" value="" />
                                <label for="lastname" className="form__label__2">Last Name</label>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="form-group">
                                <input type="text" id="email_address" className="material-input-fff transition" placeholder="Email Address" name="email_address" value="" />
                                <label for="emailid" className="form__label__2">Email ID</label>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="form-group">
                                <input type="number" id="phone_no" name="phone_no" className="material-input-fff transition" placeholder="Phone No." value="" />
                                <label for="phone" className="form__label__2">Phone</label>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="form-group">
                                <textarea className="form_cntrl transition h_auto" rows="4" placeholder="Message" name="message" value=""></textarea>
                            </div>
                        </div>
                        <div className="col-12">
                            <button type="submit" className="btn_yl w-100 transition submit-contactus">SUBMIT</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
