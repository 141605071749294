const dev = {
  logging: {
    REGION: "us-east-1",
    FIREHOSE: "jv-application-logs",
  },
  s3: {
    REGION: "us-east-1",
    BUCKET: "jonahdigital"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.jonahdna.com"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_JXCFCnLa6",
    APP_CLIENT_ID: "6bnseebkr89m0510vgusa67mst",
    IDENTITY_POOL_ID: "us-east-1:985bb2d6-7592-4c25-81ff-d543f9bae02a"
  },
  google: {
    apiKey: "AIzaSyAwC20HBIVJ5trA0u8JLry7nCxhEUaLXUA"
  },

  access: {
    "citizen": ["/user/dashboard",
      "/user/explore-data",
      "/user/explore-all-data",
      "/user/sample-detail"],
    "corporate": ["/user/dashboard",
      "/corporate-user/explore-data",
      "/corporate-user/explore-qpcr-data",
      "/corporate-user/read-data",
      "/corporate-user/qpcr-csv",
      "/corporate-user/qpcr-report",
      "/corporate-user/esv-data",
      "/corporate-user/method-data"
    ],
    "admin": ["/user/dashboard",
      "/user/explore-data",
      "/corporate-user/explore-data",
      "/corporate-user/explore-qpcr-data",
      "/corporate-user/qpcr-csv",
      "/corporate-user/qpcr-report",      
      "/corporate-user/read-data",
      "/corporate-user/esv-data",
      "/corporate-user/method-data",
      "/user/explore-all-data",
      "/user/sample-detail"]
  }
};

const prod = {
  logging: {
    REGION: "us-east-1",
    FIREHOSE: "jv-application-logs",
  },
  s3: {
    REGION: "us-east-1",
    BUCKET: "jonahdigital"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.jonahdna.com"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_JXCFCnLa6",
    APP_CLIENT_ID: "6bnseebkr89m0510vgusa67mst",
    IDENTITY_POOL_ID: "us-east-1:985bb2d6-7592-4c25-81ff-d543f9bae02a"
  },
  google: {
    apiKey: "AIzaSyAwC20HBIVJ5trA0u8JLry7nCxhEUaLXUA"
  },
  access: {
    "citizen": ["/user/dashboard",
      "/user/explore-data",
      "/user/explore-all-data",
      "/user/sample-detail"],
    "corporate": ["/user/dashboard",
      "/corporate-user/explore-data",
      "/corporate-user/read-data",
      "/corporate-user/esv-data",
      "/corporate-user/method-data"
    ],
    "admin": ["/user/dashboard",
      "/user/explore-data",
      "/corporate-user/explore-data",
      "/corporate-user/read-data",
      "/corporate-user/esv-data",
      "/corporate-user/method-data",
      "/user/explore-all-data",
      "/user/sample-detail"]
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
