import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ExploreData from "./containers/ExploreData";
import ExploreQPCRData from "./containers/ExploreQPCRdata"
import ExploreDataCorporate from "./containers/ExploreDataCorporate";
import ExploreAllData from "./containers/ExploreAllData";
import SampleDetails from "./containers/SampleDetails";
import ReadData from "./containers/GetReadData";
import ESVData from "./containers/GetESVData";
import TestMethods from "./containers/GetTestMethods";

import Home from "./containers/Home";
import TnC from "./containers/TnC";
import Privacy from "./containers/Privacy";

import Login from "./containers/Login";
import RegisterUser from "./containers/RegisterUser";
import Dashboard from "./containers/Dashboard";
import NotFound from "./containers/NotFound";
import ForgotPassword from "./containers/ForgotPassword";
import NewPassword from "./containers/NewPassword";

import VerifyUser from "./containers/VerifyUser";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AuthenticatedRoute path="/" exact component={Login} appProps={appProps} />
      <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
      <UnauthenticatedRoute path="/register" exact component={RegisterUser} appProps={appProps} />
      <UnauthenticatedRoute path="/forgot-password" exact component={ForgotPassword} appProps={appProps} />
      <UnauthenticatedRoute path="/new-password" exact component={NewPassword} appProps={appProps} />
      <UnauthenticatedRoute path="/verify-user" exact component={VerifyUser} appProps={appProps} />
      <AppliedRoute path="/terms-conditions" exact component={TnC} appProps={appProps} />
      <AuthenticatedRoute path="/user/dashboard" exact component={Dashboard} appProps={appProps} />
      <AuthenticatedRoute path="/user/explore-data" exact component={ExploreData} appProps={appProps} />
      <AuthenticatedRoute path="/corporate-user/explore-data" exact component={ExploreDataCorporate} appProps={appProps} />
      <AuthenticatedRoute path="/corporate-user/explore-qpcr-data" exact component={ExploreQPCRData} appProps={appProps} />
      <AuthenticatedRoute path="/corporate-user/qpcr-csv" exact component={ExploreDataCorporate} appProps={appProps} />
      <AuthenticatedRoute path="/corporate-user/qpcr-report" exact component={ExploreDataCorporate} appProps={appProps} />
      
      <AuthenticatedRoute path="/corporate-user/read-data/*" exact component={ReadData} appProps={appProps} />
      <AuthenticatedRoute path="/corporate-user/esv-data/*" exact component={ESVData} appProps={appProps} />
      <AuthenticatedRoute path="/corporate-user/method-data/*" exact component={TestMethods} appProps={appProps} />
      <AuthenticatedRoute path="/user/explore-all-data" exact component={ExploreAllData} appProps={appProps} />
      <AuthenticatedRoute path="/user/sample-detail" component={SampleDetails} appProps={appProps} />
      <AppliedRoute path="/privacy-policy" exact component={Privacy} appProps={appProps} />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
