import React, { useState, useEffect } from "react";
import { Auth, API, Logger } from "aws-amplify";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import "./App.css";
import Header from './containers/Header'
import Footer from './containers/Footer'
import config from './config';
import AppliedRoute from "./components/AppliedRoute";
const logger = new Logger('app');

const AWS = require('aws-sdk');
let firehose;

function App(props) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userContext, setUserContext] = useState({});

  function log(payload) {
    try {
      payload['timestamp'] = new Date();
      payload['user'] = userContext.attributes && userContext.attributes.email;
      payload['env'] = process.env.REACT_APP_STAGE
      payload = JSON.stringify(payload);
      if (payload.type === 'error') {
        logger.error(payload);
      } else if (payload.type === 'warn') {
        logger.warn(payload);
      } else if (payload.type === 'debug') {
        logger.debug(payload);
      } else {
        logger.info(payload);
      }

      let params = {
        DeliveryStreamName: config.logging.FIREHOSE, /* required */
        Record: { /* required */
          Data: JSON.stringify(payload)
        }
      };
      return firehose.putRecord(params).promise().catch((e) => {
      })
    } catch (e) {
      console.log(JSON.stringify(e, Object.getOwnPropertyNames(e)))
    }

  }

  async function httpWrapper(gw, method, path, retries, payload) {
    let stTime = new Date();
    if (method === 'POST') {
      return API.post(gw, path, {
        headers: {
          Authorization: userContext.idToken
        },
        body: (payload)
      }).then((data) => {
        log({
          "type": "info",
          "source": "httpWrapper",
          "method": method,
          "path": path,
          "payload": payload,
          "respnseTime": ((new Date()) - stTime),
          "retries": retries
        })
        return data;
      }).catch(async (e) => {
        if (retries < 2) {
          log({
            "log": e,
            "type": "error",
            "source": "httpWrapper",
            "retries": retries
          })
          throw (e);
        } else {
          await onLoad();
          return await httpWrapper(gw, method, path, (retries - 1), payload)
        }
      })
    }

    if (method === 'GET') {
      return API.get(gw, path, {
        headers: {
          Authorization: userContext.idToken
        }
      }).then((data) => {
        log({
          "type": "info",
          "source": "httpWrapper",
          "method": method,
          "path": path,
          "payload": payload,
          "respnseTime": ((new Date()) - stTime),
          "retries": retries
        })
        return data;
      }).catch(async (e) => {
        if (retries < 2) {
          log({
            "log": e,
            "type": "error",
            "source": "httpWrapper",
            "retries": retries
          })
          throw (e);
        } else {
          await onLoad();
          return await httpWrapper(gw, method, path, (retries - 1), payload)
        }
      })
    }

  }

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      let session = await Auth.currentSession();
      let creds = await Auth.currentCredentials();
      firehose = new AWS.Firehose({
        "region": config.logging.REGION,
        "accessKeyId": creds.data.Credentials.AccessKeyId,
        "secretAccessKey": creds.data.Credentials.SecretKey,
        "sessionToken": creds.data.Credentials.SessionToken
      });

      setUserContext({ "attributes": session.idToken.payload, "idToken": session.idToken.jwtToken });

      userHasAuthenticated(true);
    }
    catch (e) {
      if (e !== 'No current user') {
        //alert(e);
      }
    }

    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <div>
        <AppliedRoute component={Header} appProps={{ isAuthenticated, userHasAuthenticated, userContext, setUserContext, isLoading, setIsLoading, httpWrapper }} />

        <Routes appProps={{ isAuthenticated, userHasAuthenticated, userContext, setUserContext, isLoading, setIsLoading, httpWrapper }} />
        {isLoading ? (<div className="spinner-border" role="status" style={{
          "position": "fixed", "top": "50%", "left": "50%"
        }}>
          <span className="sr-only" >Loading...</span>
        </div>) : (<></>)}

        <div className="container-fluid p-0">
          <Footer />
        </div>
      </div>
    )
  );
}

export default withRouter(App);
