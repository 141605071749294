import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useFormFields } from "../libs/hooksLib";
import "./Login.css";
export default function Login(props) {
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    remember_me: ""
  });
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    remember_me: ""
  });

  const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

  function handleChange(event) {
    const { name, value } = event.target;
    //alert("event.target" + event.target.name + event.target.value)
    switch (name) {
      case 'email':
        errors.email =
          validEmailRegex.test(value)
            ? ''
            : 'Email is not valid';
        break;
      case 'password':
        errors.password =
          value.length === 0
            ? 'Please enter your account password'
            : '';
        break;
      default:
        break;
    }
    handleFieldChange(event)
    setErrors(errors)
  }

  const [message, setMessage] = useState('');

  function validateForm(errors) {
    let valid = true;
    Object.keys(fields).forEach(
      (val) => handleChange({ "target": { "name": val, "value": fields[val] } })
    );
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (!validateForm(errors)) {
      return;
    }

    props.setIsLoading(true);

    try {
      await Auth.signIn(fields.email.toLowerCase(), fields.password).then((data) => {
        if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
          props.setUserContext({ "UnauthUserCtx": { "email": fields.email.toLowerCase(), "user": data } });
          props.history.push("/new-password");
          return;
        }
        props.setUserContext({ "attributes": data.attributes, "idToken": data.signInUserSession.idToken.jwtToken });
        props.userHasAuthenticated(true);
        if (!props.history.location.search)
          props.history.push("/user/dashboard");
      }).catch(err => {
        if (err.message === 'User is not confirmed.') {
          props.setUserContext({ "UnauthUserCtx": { "email": fields.email.toLowerCase() } });
          props.history.push("/verify-user");
        }
        if (err.code === "PasswordResetRequiredException") {
          props.setUserContext({ "UnauthUserCtx": { "email": fields.email.toLowerCase() } });
          setMessage("Please reset your password by clicking on Forgot Password link.");
        }
        else
          setMessage(err.message);
      });
      props.setIsLoading(false);

    } catch (e) {
      alert(e.message);
      props.setIsLoading(false);
    }
  }

  function loadLoginForm() {
    return (
      <div className="card-body">

        <div className="modal_txt text-right">
          <h3>LOGIN</h3>

        </div>
        <div className="modal_left">
          <h3 className="card-title">Sign In</h3>
          <span className="card-text">Enter Your Login Details Below</span>
          {message ? (<p style={{
            padding: "5px",
            "margin-top": "20px",
            "margin-bottom": "10px",
            "font-size": "14px",
            width: "100%",
            background: "#F5F5F5",
            border: "2px solid #D64958",
            color: "#D64958"
          }}> {message} </p>) : (<></>)}
          <form id="login" onSubmit={handleSubmit} method="post" className="mt-5">
            <div className="form-group">
              <input type="text" onChange={handleChange} name="email" value={fields.email} className="material-input" id="email" placeholder="Email Address" style={{ "width": "70%" }} />
              <label className="form__label">Email Address</label>
              {errors.email.length > 0 &&
                <label id="email-error" class="error jquery-validation-error small form-text invalid-feedback" for="email" style={{ display: (errors.email ? "block" : "none") }}>{errors.email}</label>}
            </div>
            <div className="form-group">
              <input type="password" onChange={handleChange} id="password" value={fields.password} className="material-input" name="password" placeholder="Password" style={{ "width": "70%" }} />

              <label className="form__label">Password</label>
              {errors.password.length > 0 &&
                <label id="password-error" class="error jquery-validation-error small form-text invalid-feedback" for="password" style={{ display: (errors.password ? "block" : "none") }}>{errors.password}</label>}
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <div>
                <a href="/forgot-password">Forgot Password?</a>
              </div>
            </div>
            <input type="hidden" id="auth_guard" className="material-input" name="auth_guard" value='3' />
            <div className="form-group">
              <button type="submit" className="btn btn-primary transition">SUBMIT</button>
            </div>
            <div className="lg_info">
              Don't have an account? <a href="/register" >SignUp</a>
            </div>
          </form>
        </div>
      </div>)
  }

  return (
    <div className="container">
      <section className="op_p" style={{ "border": "none", "background": "transparent" }}>
        <div className="card" style={{ "border": "light", "background": "transparent", width: "100%" }}>
          {loadLoginForm()},
        </div></section></div>
  );
}
