import React from "react";
import { Route, Redirect } from "react-router-dom";
import config from "../config";

function verifyPathAccess(props, appProps) {
  return (config.access[appProps.userContext.attributes['custom:type'] || 'citizen'].find((path) => {
    return props.location.pathname.startsWith(path);
  }))
}

export default function AuthenticatedRoute({ component: C, appProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        appProps.isAuthenticated
          ? verifyPathAccess(props, appProps) ? <C {...props} {...appProps} /> : <Redirect
            to={`/user/dashboard`}
          />
          : <Redirect
            to={`/login?redirect=${props.location.pathname}${props.location
              .search}`}
          />}
    />
  );
}
