import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useFormFields } from "../libs/hooksLib";
import "./Login.css";

export default function Login(props) {
    const [fields, handleFieldChange] = useFormFields({
        phoneno: "",
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirmpasswd: "",
        Address: "",
        City: "",
        State: "",
        pin: "",
        accountType:"citizen"
    });
    const [errors, setErrors] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirmpasswd: "",
        phoneno: ""
    });
    const [message, setMessage] = useState('');


    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    function handleChange(event) {
        const { name, value } = event.target;
        switch (name) {
            case 'email':
                errors.email =
                    validEmailRegex.test(value)
                        ? ''
                        : 'Email is not valid';
                break;
            case 'password':
                errors.password =
                    value.length < 9
                        ? 'Password must be 9 characters long'
                        : '';
                break;
            case 'confirmpasswd':
                errors.confirmpasswd =
                    value.length < 9
                        ? 'Password must be 9 characters long'
                        : '';
                break;
            case 'firstname':
                errors.firstname =
                    value.length === 0
                        ? 'First Name is required'
                        : '';
                break;
            case 'lastname':
                errors.lastname =
                    value.length === 0
                        ? 'Last Name is required'
                        : '';
                break;
            case 'phoneno':
                errors.phoneno =
                    value.length < 10
                        ? 'Phone number must have 10 digits'
                        : '';
                break;
            default:
                break;
        }
        handleFieldChange(event)
        setErrors(errors)
    }

    function validateSingupForm(errors) {
        let valid = true;
        Object.keys(fields).forEach(
            (val) => handleChange({ "target": { "name": val, "value": fields[val] } })
        );
        Object.values(errors).forEach(
            (val) => val.length > 0 && (valid = false)
        );
        if (valid) {
            if (fields.password !== fields.confirmpasswd) {
                valid = false;
                errors.confirmpasswd = 'Please enter the same value again.'
            }
        }
        return valid;
    }

    function singupForm() {
        return (<>
            <div className="modal_txt text-right">
                <h3>SIGNUP</h3>

            </div>
            <div className="modal_left mobile-overlow">
                <h3 className="title">Signup</h3>
                <span className="card-text">Please fill out the following fields</span>
                <div />

                {message ? (<p style={{
                    padding: "5px",
                    "margin-top": "20px",
                    "margin-bottom": "10px",
                    "font-size": "14px",
                    width: "100%",
                    background: "#F5F5F5",
                    border: "2px solid #D64958",
                    color: "#D64958"
                }}> {message} </p>) : (<></>)}
                <form className="mt-5" id="signupform" method="post" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="form-group">
                                <input type="text" onChange={handleChange} id="firstname" value={fields.firstname} className="material-input" placeholder="First Name" name="firstname" />
                                <label className="form__label">First Name</label>
                                {errors.firstname.length > 0 &&
                                    <label id="email-error" class="error jquery-validation-error small form-text invalid-feedback" for="password" style={{ display: (errors.firstname ? "block" : "none") }}>{errors.firstname}</label>}
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="form-group">
                                <input type="text" onChange={handleChange} id="lastname" value={fields.lastname} className="material-input" placeholder="Last Name" name="lastname" />
                                <label className="form__label">Last Name</label>
                                {errors.lastname.length > 0 &&
                                    <label id="email-error" class="error jquery-validation-error small form-text invalid-feedback" for="password" style={{ display: (errors.lastname ? "block" : "none") }}>{errors.lastname}</label>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="form-group">
                                <input type="text" onChange={handleChange} id="email"  value={fields.email} className="material-input" placeholder="Email Address" name="email_address" />
                                <label className="form__label">Email Address</label>
                                {errors.email.length > 0 &&
                                    <label id="email-error" class="error jquery-validation-error small form-text invalid-feedback" for="password" style={{ display: (errors.email ? "block" : "none") }}>{errors.email}</label>}
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="form-group">
                                <input type="number" onChange={handleChange} id="phoneno" name="phoneno"  value={fields.phoneno} className="material-input" placeholder="Phone No." />
                                <label className="form__label">Phone No.</label>
                                {errors.phoneno.length > 0 &&
                                    <label id="email-error" class="error jquery-validation-error small form-text invalid-feedback" for="password" style={{ display: (errors.phoneno ? "block" : "none") }}>{errors.phoneno}</label>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="form-group">
                                <input type="password" onChange={handleChange} id="password" name="password"  value={fields.password} className="material-input" placeholder="Password" />
                                <label className="form__label">Password</label>
                                {errors.password.length > 0 &&
                                    <label id="email-error" class="error jquery-validation-error small form-text invalid-feedback" for="password" style={{ display: (errors.password ? "block" : "none") }}>{errors.password}</label>}
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="form-group">
                                <input type="password" onChange={handleChange} id="confirmpasswd" name="confirmed" value={fields.confirmpasswd} className="material-input" placeholder="Confirm Password" />
                                <label className="form__label">Confirm Password</label>
                                {errors.confirmpasswd.length > 0 &&
                                    <label id="email-error" class="error jquery-validation-error small form-text invalid-feedback" for="password" style={{ display: (errors.confirmpasswd ? "block" : "none") }}>{errors.confirmpasswd}</label>}
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="form-group">
                                <select type="select" onChange={handleChange} id="accountType" name="accountType" className="material-input" placeholder="Account Type">
                                {(fields.accountType === 'citizen') ? (<option value="citizen" selected>Citizen Scientist</option>):  (<option value="citizen">Citizen Scientist</option>)}                                    
                                {(fields.accountType === 'corporate') ? (<option value="corporate" selected>Research / Corporate</option>):  (<option value="corporate">Research / Corporate</option>)}                        
                                </select>
                                <label className="form__label">Account Type</label>
                            </div>
                        </div>                        
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary transition" >SUBMIT</button>
                    </div>
                    <div className="lg_info ">
                        Already have an account? <a href="/login" >Login</a>
                    </div>
                </form>
            </div></>)
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setMessage('');
        if (!validateSingupForm(errors)) {
            return;
        }

        props.setIsLoading(true);

        try {
            Auth.signUp({
                username: fields.email.toLowerCase(),
                password: fields["password"],
                attributes: {
                    email: fields.email.toLowerCase(),
                    "custom:role": "user",
                    "custom:type": fields.accountType,
                    "given_name": fields.firstname,
                    "family_name": fields.lastname,
                    "phone_number": ("+1" + fields.phoneno),   // optional - E.164 number convention
                    // other custom attributes 
                },
                validationData: []  //optional
            })
                .then(data => {
                    props.setUserContext({ "UnauthUserCtx": { "email": fields.email.toLowerCase(), "user": data } });
                    props.history.push("/verify-user");
                })
                .catch(err => {
                    setMessage(err.message);
                });


            // After retrieving the confirmation code from the user
            /*
            Auth.confirmSignUp(fields.email, code, {
                // Optional. Force user confirmation irrespective of existing alias. By default set to True.
                forceAliasCreation: true    
            }).then(data => alert(data))
              .catch(err => alert(err));
              */
            props.setIsLoading(false);
        } catch (e) {
            setMessage(e.message);
            props.setIsLoading(false);
        }
    }

    return (
        <div className="container">
            <section className="op_p" style={{ "border": "none", "background": "transparent" }}>
                <div className="card" style={{ "border": "light", "background": "transparent" }}>
                    <div className="card-body">
                        {singupForm()}
                    </div>
                </div></section></div>
    );
}
