import React, { useCallback, useState, useEffect } from "react";
import "./Login.css";
import { Helmet } from "react-helmet";
import Swal from 'sweetalert2';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import { compose, withProps } from "recompose";
import { API } from "aws-amplify";
import qs from "qs";
import withReactContent from "sweetalert2-react-content";
import { S3Image } from 'aws-amplify-react';
import "chartjs-plugin-annotation";


import config from "../config";

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";

const moment = require('moment');
const MySwal = withReactContent(Swal);

const MyMapComponent = compose(
    withProps({
        googleMapURL:
            `https://maps.googleapis.com/maps/api/js?key=${config.google.apiKey}&callback=Function.prototype&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `620px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap defaultZoom={6} defaultCenter={props.marker}>
        <Marker position={props.marker} />
    </GoogleMap>
));

//import { SweetAlert } from 'react-bootstrap-sweetalert';


//const SweetAlert = withSwalInstance(swal);

export default function SampleDetails(props) {
    const [sicName, setsicName] = useState('');
    const [mapImg, setmapImg] = useState('');
    const [spImg, setspImg] = useState('');
    const [badgeImg,setBadgeImg] = useState('');
    const [comName, setcomName] = useState('');
    const [desc, setdesc] = useState('');
    const [showSideNav, setshowSideNav] = useState(false);
    const [marker, setMarker] = useState();
    const [orderChart, setOrderChart] = useState();
    const [familyChart, setFamilyChart] = useState();
    const [phylumChart, setPhylumChart] = useState();
    const [classChart, setClassChart] = useState();
    const [genusChart, setGenusChart] = useState();
    const [test, setTest] = useState("Fish");
    const [category, setCategory] = useState("");
    const [line, setLine] = useState([
        {
            type: "line",
            mode: "vertical",
            scaleID: "x-axis-0",
            value: 0,
            borderColor: "red",
            borderWidth: 0,
            label: {
                content: "This Sample",
                enabled: false,
                backgroundColor: 'rgba(0,0,0,0.8)'
            }
        }
    ]);
    const [hasFish, setHasFish] = useState(false);
    const [hasAlgae, setHasAlgae] = useState(true);
    const [richnessData, setRichness] = useState({
        labels: [],
        datasets: [
            {
                label: '% Samples',
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: []
            }
        ]
    });

    let referrer = qs.parse(props.location.search, { ignoreQueryPrefix: true }).referrer;


    const legend = {
        display: false,
        position: "bottom",
        labels: {
            fontColor: "#323130",
            fontSize: 14
        }
    };


    const [sampleInfo, setSampleInfo] = useState({});
    const [taxInfo, setTaxInfo] = useState({});
    const role = props.userContext.attributes['custom:role'];
    const email = props.userContext.attributes['email'];
    const clientId = (props.userContext.attributes['custom:clientid'] || email).toLowerCase();

    async function loadSampleInfo() {
        let batchId = qs.parse(props.location.search, { ignoreQueryPrefix: true }).batchid;
        let sampleId = qs.parse(props.location.search, { ignoreQueryPrefix: true }).sampleid;
        return props.httpWrapper("jv-portal-apis", 'GET', `/sample?batchid=${batchId}&sampleid=${sampleId}`, 2)
    }

    async function loadTaxInfo() {
        let batchId = qs.parse(props.location.search, { ignoreQueryPrefix: true }).batchid;
        let sampleId = qs.parse(props.location.search, { ignoreQueryPrefix: true }).sampleid;
        return props.httpWrapper("jv-portal-apis", 'GET', `/sample/esv?batchid=${batchId}&sampleid=${sampleId}`, 2)
    }

    async function loadRichnessMetrics() {
        let batchId = qs.parse(props.location.search, { ignoreQueryPrefix: true }).batchid;
        let sampleId = qs.parse(props.location.search, { ignoreQueryPrefix: true }).sampleid;
        return props.httpWrapper("jv-portal-apis", 'GET', `/metrics/richness?batchid=${batchId}&sampleid=${sampleId}`, 2)
    }

    async function onLoad() {
        try {
            const sampleInfoRes = (await loadSampleInfo())[0];
            if (sampleInfoRes) {
                setMarker({ "lat": parseFloat(sampleInfoRes.Lat), "lng": parseFloat(sampleInfoRes.Long) })
                if (sampleInfoRes.BatchImageId)
                setBadgeImg("badges/" + sampleInfoRes.BatchImageId);
                //props.setIsLoading(false);
                setSampleInfo({
                    "SampleDate": moment.utc(sampleInfoRes.CaptureTimeTs).local().format('MM/DD/YYYY'),
                    "SampleTime": moment.utc(sampleInfoRes.CaptureTimeTs).local().format('HH:mm:ss'),
                    "CaptureTimeTs": sampleInfoRes.CaptureTimeTs,
                    "SiteName": (sampleInfoRes.SiteName || ' '),
                    "SampleId": sampleInfoRes.SampleId,
                    "BatchId": sampleInfoRes.BatchId,
                    "VolumeWater": sampleInfoRes.VolumeWater || ' ',
                    "SampleNotes": (sampleInfoRes.SampleNotes || sampleInfoRes.Note || ' '),
                    "Lat": sampleInfoRes.Lat,
                    "Long": sampleInfoRes.Long,
                    "PublishStatus": (sampleInfoRes.PublishStatus || 'Private'),
                    "ReceivedDate": sampleInfoRes.ReceivedDate && moment.utc(sampleInfoRes.ReceivedDate).local().format('MM/DD/YYYY'),
                    "readonly": !(clientId === sampleInfoRes.ClientId.toLowerCase() || role === 'admin')
                });
            }
        } catch (e) {
            props.setIsLoading(false);
        }

    }

    async function loadTax() {
        try {
            const taxInfoRes = (await loadTaxInfo());
            let genusChart = { "TotalReads": 0 };
            let familyChart = { "TotalReads": 0 };
            let orderChart = { "TotalReads": 0 };
            let classChart = { "TotalReads": 0 };
            let phylumChart = { "TotalReads": 0 };
            let testType = {};

            let fishTax = taxInfoRes.filter((it) => {
                return it.TestId === 'Fish';
            })
            let fishMap = {};
            let fishCount = 0;

            fishTax.map((it) => {
                if (!fishMap[it.RepGenus + " " + it.RepSpecies]) {
                    fishCount = fishCount + 1;
                    fishMap[it.RepGenus + " " + it.RepSpecies] = 1;
                }
            })
            //alert (JSON.stringify(fishTax));
            setLine([
                {
                    type: "line",
                    mode: "vertical",
                    scaleID: "x-axis-0",
                    value: fishCount,
                    borderColor: "red",
                    borderWidth: 4,
                    label: {
                        content: "This Sample",
                        position: "top",
                        fontSize: 8,
                        enabled: true,
                        backgroundColor: 'rgba(0,0,0,0.8)'
                    }
                }
            ])

            let richnessResp = await loadRichnessMetrics();
            let numSamples = richnessResp.NumSamples;
            let numSpecies = richnessResp.NumSpecies;
            let matchingSpecies = numSpecies.filter((it) => it === fishCount);
            let isMatching = matchingSpecies.length;
            let nNumSamples = []
            let nNumSpecies = []

            if (isMatching === 0 && fishCount > 0) {
                let hasFound = false;
                for (let i = 0; i < numSpecies.length; i++) {
                    if (numSpecies[i] > fishCount) {
                        nNumSamples.push(0.1);
                        nNumSpecies.push(fishCount);
                        hasFound = true;
                    }
                    nNumSamples.push(numSamples[i]);
                    nNumSpecies.push(numSpecies[i]);
                }

                if (!hasFound) {
                    nNumSamples.push(0.1);
                    nNumSpecies.push(fishCount);
                }

            }

            if (nNumSpecies.length > 0) {
                numSamples = nNumSamples;
                numSpecies = nNumSpecies;
            }

            setRichness({
                labels: numSpecies,
                datasets: [
                    {
                        label: '% Samples',
                        backgroundColor: 'rgba(75,192,192,1)',
                        borderColor: 'rgba(0,0,0,1)',
                        borderWidth: 2,
                        data: numSamples
                    }
                ]
            });

            //props.setIsLoading(false);
            //alert(JSON.stringify(taxInfoRes));
            if (Array.isArray(taxInfoRes)) {
                setTaxInfo(taxInfoRes);
                taxInfoRes.map((it) => {
                    testType[it.TestId] = it.TestId;
                    if (it.TestId === 'Algae') {
                        genusChart.TotalReads = genusChart.TotalReads + it.PercReads;
                        familyChart.TotalReads = familyChart.TotalReads + it.PercReads;
                        orderChart.TotalReads = orderChart.TotalReads + it.PercReads;
                        classChart.TotalReads = classChart.TotalReads + it.PercReads;
                        phylumChart.TotalReads = phylumChart.TotalReads + it.PercReads;

                        if (genusChart[it.RepGenus]) {
                            genusChart[it.RepGenus].PercReads = genusChart[it.RepGenus].PercReads + it.PercReads;
                        } else {
                            genusChart[it.RepGenus] = { "PercReads": it.PercReads };
                        }

                        if (familyChart[it.RepFamily]) {
                            familyChart[it.RepFamily].PercReads = familyChart[it.RepFamily].PercReads + it.PercReads;
                        } else {
                            familyChart[it.RepFamily] = { "PercReads": it.PercReads };
                        }

                        if (orderChart[it.RepOrder]) {
                            orderChart[it.RepOrder].PercReads = orderChart[it.RepOrder].PercReads + it.PercReads;
                        } else {
                            orderChart[it.RepOrder] = { "PercReads": it.PercReads };
                        }

                        if (classChart[it.RepClass]) {
                            classChart[it.RepClass].PercReads = classChart[it.RepClass].PercReads + it.PercReads;
                        } else {
                            classChart[it.RepClass] = { "PercReads": it.PercReads };
                        }

                        if (phylumChart[it.RepPhylum]) {
                            phylumChart[it.RepPhylum].PercReads = phylumChart[it.RepPhylum].PercReads + it.PercReads;
                        } else {
                            phylumChart[it.RepPhylum] = { "PercReads": it.PercReads };
                        }
                    }

                })
            }

            Object.keys(testType).map((key) => {
                if (key === 'Fish') {
                    setHasFish(true)
                } else if (key === 'Algae')
                    setHasAlgae(true);
            })
            let genusLabels = []
            let genusPerc = []
            let familyLabels = []
            let familyPerc = []
            let classLabels = []
            let classPerc = []
            let orderLabels = []
            let orderPerc = []
            let phylumLabels = []
            let phylumPerc = []

            let backgroundColor = [
                "#2196F3",
                "#F44336",
                "#FFC107",
                "#2196F3",
                "#F44336",
                "#FFC107",
                "#2196F3",
                "#F44336",
                "#FFC107",
                "#2196F3",
                "#F44336",
                "#FFC107",
            ];
            let hoverBackgroundColor = [
                "#2196F3",
                "#F44336",
                "#FFC107",
                "#2196F3",
                "#F44336",
                "#FFC107",
                "#2196F3",
                "#F44336",
                "#FFC107",
                "#2196F3",
                "#F44336",
                "#FFC107"
            ]
            let perc;

            Object.keys(genusChart).map((key) => {
                if (key !== 'TotalReads') {
                    perc = (genusChart[key].PercReads / genusChart.TotalReads * 100).toFixed(2);
                    if (perc > 0) {
                        genusLabels.push(key);
                        genusPerc.push(perc);
                    }

                }

            })
            genusPerc = genusPerc.sort((a, b) => b - a);
            setGenusChart({ "labels": genusLabels, "datasets": [{ "data": genusPerc, "hoverBackgroundColor": hoverBackgroundColor, "backgroundColor": backgroundColor }] });

            Object.keys(familyChart).map((key) => {
                if (key !== 'TotalReads') {
                    perc = Math.round(familyChart[key].PercReads / familyChart.TotalReads * 100, 2);
                    if (perc > 0) {
                        familyLabels.push(key);
                        familyPerc.push(perc);
                    }
                }

            })

            familyPerc = familyPerc.sort((a, b) => b - a);
            setFamilyChart({ "labels": familyLabels, "datasets": [{ "data": familyPerc, "hoverBackgroundColor": hoverBackgroundColor, "backgroundColor": backgroundColor }] });

            Object.keys(orderChart).map((key) => {
                if (key !== 'TotalReads') {
                    perc = Math.round(orderChart[key].PercReads / orderChart.TotalReads * 100, 2);
                    if (perc >= 0) {
                        orderLabels.push(key);
                        orderPerc.push(perc);
                    }
                }

            })

            orderPerc = orderPerc.sort((a, b) => b - a);
            setOrderChart({ "labels": orderLabels, "datasets": [{ "data": orderPerc, "hoverBackgroundColor": hoverBackgroundColor, "backgroundColor": backgroundColor }] });

            Object.keys(phylumChart).map((key) => {
                if (key !== 'TotalReads') {
                    perc = Math.round(phylumChart[key].PercReads / phylumChart.TotalReads * 100, 2);
                    if (perc > 0) {
                        phylumLabels.push(key);
                        phylumPerc.push(perc);
                    }
                }

            })

            phylumPerc = phylumPerc.sort((a, b) => b - a);
            setPhylumChart({ "labels": phylumLabels, "datasets": [{ "data": phylumPerc, "hoverBackgroundColor": hoverBackgroundColor, "backgroundColor": backgroundColor }] });

            Object.keys(classChart).map((key) => {
                if (key !== 'TotalReads') {
                    perc = Math.round(classChart[key].PercReads / classChart.TotalReads * 100, 2);
                    if (perc > 0) {
                        classLabels.push(key);
                        classPerc.push(perc);
                    }
                }

            })

            classPerc = classPerc.sort((a, b) => b - a);
            setClassChart({ "labels": classLabels, "datasets": [{ "data": classPerc, "hoverBackgroundColor": hoverBackgroundColor, "backgroundColor": backgroundColor }] });
            //alert(JSON.stringify(orderChart))
        } catch (e) {
            alert(e)
            props.setIsLoading(false);
        }

    }

    useEffect(() => {
        props.setIsLoading(true);
        loadAllData();
    }, []);

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setshowSideNav(false)
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    async function loadAllData() {
        return Promise.all([onLoad(), loadTax()]).then(([res1, res2]) => {

        }).finally(() => {
            props.setIsLoading(false)
        });
    }
    async function updSampleInfo(updObj) {
        return API.post("jv-portal-apis", `/sample`, {
            headers: {
                Authorization: props.userContext.idToken
            },
            body: (updObj)
        })
    }

    //onLoad();

    const [chartData, setchartData] = useState({
        labels: [
        ],
        datasets: [{
            data: [
            ],
            backgroundColor: [
                "#2196F3",
                "#F44336",
                "#FFC107",
                "#2196F3",
                "#F44336",
                "#FFC107",
                "#2196F3",
                "#F44336",
                "#FFC107",
                "#2196F3",
                "#F44336",
                "#FFC107",
            ],
            hoverBackgroundColor: [
                "#2196F3",
                "#F44336",
                "#FFC107",
                "#2196F3",
                "#F44336",
                "#FFC107",
                "#2196F3",
                "#F44336",
                "#FFC107",
                "#2196F3",
                "#F44336",
                "#FFC107",
            ]
        }]
    });

    function handleTestChange(event) {
        setTest(event.target.value);
        if (genusChart)
            setchartData(genusChart);
    }

    function setDropDown() {
        if (!hasFish)
            handleTestChange({ target: { value: "Algae" } });
    }

    function handleCategoryChange(event) {
        setCategory(event.target.value)
        if (event.target.value === 'genus') {
            setchartData(genusChart)
        } else if (event.target.value === 'family') {
            setchartData(familyChart)
        } else if (event.target.value === 'order') {
            setchartData(orderChart)
        } else if (event.target.value === 'class') {
            setchartData(classChart)
        } else if (event.target.value === 'phylum') {
            setchartData(phylumChart)
        }

    }

    function getAbundanceRows() {
        return (Object.keys(chartData.labels).map((it) => {
            return <tr>
                <td width="50%">{chartData.labels[it]}</td>
                <td width="50%">{chartData.datasets[0].data[it]}</td>
            </tr>
        }))
    }

    function getTaxRows(taxArray) {
        //alert("->"+JSON.stringify(taxArray))
        if (Array.isArray(taxArray)) {
            let dedup = {};
            let dedupArr = [];
            let hasFish = false;
            taxArray.map((it => {
                if (!dedup[it.RepGenus + " " + it.RepSpecies]) {
                    if (it.TestId === 'Fish') {
                        hasFish = true;
                        dedupArr.push(it);
                    }
                    dedup[it.RepGenus + " " + it.RepSpecies] = it.RepGenus + " " + it.RepSpecies;
                }
            }))
            if (!hasFish) {
                //handleTestChange({target:{value:"Algae"}});
            }

            return dedupArr.map((it) => {
                return (
                    <tr key={it.ESVId} onClick={() => openImage(it.DistributionMapId, it.ImageId, it.SpeciesDescription, it.RepGenus + " " + it.RepSpecies, it.RepCommonName)}>
                        <td width="50%">{it.RepGenus + " " + it.RepSpecies}</td>
                        <td width="50%">{it.RepCommonName}</td>
                    </tr>
                )
            })
        }
    }

    function showModal(field, title, type) {
        let html;

        if (type === 'date') {
            html = "<input readonly id='modalId'  value= '" + sampleInfo[field] + "' onmousedown = 'function x(){$(" + "\"#modalId\").bootstrapMaterialDatePicker({ weekStart: 0,time: false,format : " + "\"MM/DD/YYYY\"});};x();' onClick = 'function x(){$(" + "\"#modalId\").bootstrapMaterialDatePicker({ weekStart: 0,time: false,format : " + "\"MM/DD/YYYY\"});};x();' className='swal2-input'>";
        } else if (type === 'time') {
            html = "<input readonly id='modalId'  value= '" + sampleInfo[field] + "' onmousedown = 'function x(){$(" + "\"#modalId\").bootstrapMaterialDatePicker({ date: false,format : " + "\"HH:mm\"});};x();' onClick = 'function x(){$(" + "\"#modalId\").bootstrapMaterialDatePicker({ date: false,format : " + "\"hh:mm a\"});};x();'  className='swal2-input'>";
        } else if (type === 'number') {
            html = "<input id='modalId'  type='number' value= '" + sampleInfo[field] + "'  className='swal2-input'>";
        } else
            html = "<input id='modalId' value= '" + sampleInfo[field] + "'  className='swal2-input'>";

        MySwal.fire({
            html: (field === 'PublishStatus' ? '' : html),
            input: (field === 'PublishStatus' ? 'select' : ''),
            title: title,
            inputOptions: (field === 'PublishStatus' ? { 'Public': 'Public', 'Private': 'Private' } : ''),
            inputValue: (field === 'PublishStatus' ? sampleInfo[field] : ''),
            showCancelButton: "true",
            preConfirm: (data) => {
                return document.getElementById('modalId') ? document.getElementById('modalId').value : data

            }
        }).then(async value => {
            if (!value.dismiss) {
                if (!value.value) {
                    alert('Please enter a valid value')
                    return;
                }
                let newObj = Object.assign({}, sampleInfo);
                //alert("->"+value.value.replace(/\//gi, '-') + " " + sampleInfo.SampleTime+"<--");
                //alert  (moment(value.value.replace(/\//gi, '-') + " " + sampleInfo.SampleTime,  "MM-DD-YYYY HH:mm:ss")) 
                if (field === "SampleDate") {
                    newObj.CaptureTimeTs = (moment(value.value.replace(/\//gi, '-') + " " + sampleInfo.SampleTime, "MM-DD-YYYY HH:mm:ss")) * 1
                }
                if (field === "SampleTime") {
                    newObj.CaptureTimeTs = (moment(sampleInfo.SampleDate.replace(/\//gi, '-') + " " + value.value, "MM-DD-YYYY HH:mm:ss")) * 1
                }
                newObj[field] = value.value;
                //alert(JSON.stringify(newObj))
                props.setIsLoading(true);
                await updSampleInfo(newObj);
                await onLoad();
                props.setIsLoading(false);

            }

        });
    }

    function openImage(mImg, sImg, des, sName, cName) {
        setdesc(des);
        setcomName(cName);
        setsicName(sName);

        if (mImg) {
            setmapImg("Jonah.FishMapRaster/" + mImg);
        } else {
            setmapImg("Jonah.FishMapRaster/no_Image.png");
        }

        if (sImg) {
            setspImg("FishImageTSNRaster/" + sImg);
        } else {
            setspImg("FishImageTSNRaster/no_Image.png");
        }
        setshowSideNav(true);
    }

    function closeNav() {
        setshowSideNav(false);
    }

    return (
        <section className="db_sec">
            <Helmet>
            </Helmet>
            <div className="container">
                <h3 className="hm_headng">EXPLORE YOUR DATA</h3>
                <div className="db_content">
                    <ul className="nav nav-tabs db_tabs">
                        <li className="nav-item">
                            {referrer === 'alldata' ? (
                                <a className="nav-link active" href="/user/explore-all-data">ALL SAMPLES</a>
                            ) : (
                                    <a className="nav-link active" href="/user/explore-data">MY SAMPLES</a>
                                )}
                        </li>
                    </ul>


                    <div className="tab-content">
                        <div className="tab-pane fade mt-5 active show" id="mydata">
                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                <div>
                                    <ul className="nav nav-tabs db_tabs_2">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#sampleinfo">SAMPLE INFO</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#taxa" disabled={true} onClick={setDropDown}>TAXA</a>
                                        </li>
                                        {(hasFish && !sampleInfo.readonly) ? (<li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#metrics" disabled={true} onClick={setDropDown}>METRICS</a>
                                        </li>) : (<></>)}
                                        {(hasFish && !sampleInfo.readonly && badgeImg) ? (<li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#badge" disabled={true} onClick={setDropDown}>BADGE</a>
                                        </li>) : (<></>)}                                        

                                    </ul>
                                </div>
                                <div className="tb_right">
                                    Sample Id : <span>{sampleInfo.SampleId}</span>
                                </div>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane active" id="sampleinfo">
                                    <div className="profile_info">
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <table className="table db_table_td mt-4">
                                                    <tbody>
                                                        <tr>
                                                            <td width="50%">Date</td>
                                                            <td width="40%" id="sample_date">{sampleInfo.SampleDate}</td>
                                                            {sampleInfo.readonly ? (
                                                                <td width="10%"></td>
                                                            ) : (
                                                                    <td width="10%"><i className="fas fa-edit" data-id="sample_date" onClick={() => showModal('SampleDate', "Sample Date", 'date')} data-type="date" data-placeholder="Sample Date"></i></td>
                                                                )}
                                                        </tr>
                                                        <tr>
                                                            <td width="50%">Time</td>
                                                            <td width="40%" id="sample_time">{sampleInfo.SampleTime}</td>
                                                            {sampleInfo.readonly ? (
                                                                <td width="10%"></td>
                                                            ) : (
                                                                    <td width="10%"><i className="fas fa-edit" data-id="sample_time" onClick={() => showModal('SampleTime', "SampleTime", 'time')} data-type="time" data-placeholder="Sample Time"></i></td>
                                                                )}
                                                        </tr>
                                                        <tr>
                                                            <td width="50%">Lat</td>
                                                            <td width="40%" id="latitude">{sampleInfo.Lat}</td>
                                                            {sampleInfo.readonly ? (
                                                                <td width="10%"></td>
                                                            ) : (
                                                                    <td width="10%"><i className="fas fa-edit" data-id="latitude" onClick={() => showModal('Lat', "Latitude", 'number')} data-type="text" data-placeholder="Latitude"></i></td>
                                                                )}
                                                        </tr>
                                                        <tr>
                                                            <td width="50%">Long</td>
                                                            <td width="40%" id="longitude">{sampleInfo.Long}</td>
                                                            {sampleInfo.readonly ? (
                                                                <td width="10%"></td>
                                                            ) : (
                                                                    <td width="10%"><i className="fas fa-edit" data-id="longitude" onClick={() => showModal('Long', "Longitude", 'number')} data-type="text" data-placeholder="Longitude"></i></td>
                                                                )}
                                                        </tr>
                                                        <tr>
                                                            <td width="50%">Publish Status</td>
                                                            <td width="40%" id="is_public">{sampleInfo.PublishStatus}</td>
                                                            {sampleInfo.readonly ? (
                                                                <td width="10%"></td>
                                                            ) : (
                                                                    <td width="10%"><i className="fas fa-edit" data-id="is_public" data-val="0" onClick={() => showModal('PublishStatus', "Publish Status")} data-type="select" data-placeholder="Publish Status"></i></td>
                                                                )}
                                                        </tr>

                                                        <tr>
                                                            <td width="50%">User Site Name</td>
                                                            <td width="40%" id="site_name">{sampleInfo.SiteName}</td>
                                                            {sampleInfo.readonly ? (
                                                                <td width="10%"></td>
                                                            ) : (
                                                                    <td width="10%"><i className="fas fa-edit" data-id="site_name" onClick={() => showModal('SiteName', "Site Name")} data-type="text" data-placeholder="Site Name"></i></td>
                                                                )}
                                                        </tr>
                                                        <tr>
                                                            <td width="50%">Water Volume</td>
                                                            <td width="40%" id="water_value">{sampleInfo.VolumeWater}</td>
                                                            {sampleInfo.readonly ? (
                                                                <td width="10%"></td>
                                                            ) : (
                                                                    <td width="10%"><i className="fas fa-edit" data-id="water_value" onClick={() => showModal('VolumeWater', "Water Volume")} data-type="text" data-placeholder="Water Value"></i></td>
                                                                )}
                                                        </tr>
                                                        {sampleInfo.readonly ? (<></>) : (<tr>
                                                            <td width="50%">Sample Note</td>
                                                            <td width="40%" id="sample_notes">{sampleInfo.SampleNotes}</td>
                                                            {sampleInfo.readonly ? (
                                                                <td width="10%"></td>
                                                            ) : (
                                                                    <td width="10%"><i className="fas fa-edit" data-id="sample_notes" onClick={() => showModal('SampleNotes', "Sample Notes")} data-type="text" data-placeholder="Sample Notes"></i></td>
                                                                )}
                                                        </tr>)}
                                                        {sampleInfo.readonly ? (<></>) : (

                                                            <tr>
                                                                <td width="50%">Date Received by Lab</td>
                                                                <td width="40%" id="sample_received_date">{sampleInfo.ReceivedDate}</td>
                                                                <td width="10%"></td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <div>
                                                    <p>
                                                        <em>
                                                            Species identifications presented here may not be accurate. The same sequence might be held by multiple species and may not be an exact match to organisms that have already been sequenced. New fish and algae species are currently being sequenced and added to the library to improve identifications. Note, these results should not to be used for any scientific or regulatory purpose at this time. These results are intended for educational and recreational use only.
                                                        </em>
                                                    </p>
                                                </div>
                                            </div>
                                            {props.isLoading ? (<></>) : (
                                                <div className="col-12 col-lg-6" >
                                                    <MyMapComponent marker={marker} />
                                                </div>)}

                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="taxa">
                                    {(!taxInfo || taxInfo.length === 0) ? (<div>
                                        <h4 className="no-data-found">No report submitted yet</h4>
                                    </div>) : (<div className="profile_info">
                                        <div className="d-flex justify-content-between align-items-center taxa_inf flex-wrap">
                                            <div>
                                                <h4>SPECIES PRESENT</h4>
                                            </div>
                                            <div className="mb_select">
                                                <select className="form_cntrl2" onChange={handleTestChange} id="op_selector" value={test}>
                                                    {hasFish ? (<option value="Fish">Fish</option>) : (<></>)}
                                                    {hasAlgae ? (<option value="Algae">Algae</option>) : (<></>)}
                                                </select>
                                                <select className="form_cntrl2" onChange={handleCategoryChange} id="algae_select" style={hasFish ? { "display": "none" } : {}}>
                                                    <option value="genus">Genus</option>
                                                    <option value="family">Family</option>
                                                    <option value="phylum">Phylum</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mt-3 all_info" style={!hasFish ? { "display": "none" } : {}}>
                                            <table className="table db_table_th" cellPadding="0" cellSpacing="0">
                                                <thead>
                                                    <tr>
                                                        <th width="50%">
                                                            Species
                                                        </th>
                                                        <th width="50%">
                                                            Common Name
                                                        </th>
                                                    </tr>
                                                </thead>
                                            </table>
                                            <table className="table db_table_td mt-4">
                                                {(props.isLoading ? (<></>) : (<tbody>
                                                    {getTaxRows(taxInfo)}
                                                </tbody>))}

                                            </table>
                                        </div>
                                        <div className="select_info" style={hasFish ? { "display": "none" } : {}}>
                                            <div className="row align-items-top" id="algaedata">
                                                <div className="col-12 col-lg-6">
                                                    <table className="table db_table_th" cellPadding="0" cellSpacing="0">

                                                        <thead>
                                                            <tr>
                                                                <th width="50%">
                                                                    Taxa
                                                                </th>
                                                                <th width="50%">
                                                                    % Abundance
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                    <table className="table db_table_td mt-4">
                                                        <tbody>
                                                            {getAbundanceRows()}
                                                        </tbody>

                                                    </table>
                                                </div>
                                                <div className="col-12 col-lg-6 text-center">
                                                    <div className="charts" style={{ "background": "inherit" }}>
                                                        <div data-duration="500" className="charts-loader enabled" style={{ "display": "none", "position": "relative", "top": "170px", "height": "0" }}>
                                                            <center>
                                                                <div className="loading-spinner" style={{ "border": "3px solid #000000", "borderRightColor": "transparent" }}></div>
                                                            </center>
                                                        </div>
                                                        <div className="charts-chart">

                                                            <div >
                                                                <Doughnut data={chartData} height={400} width={400} />

                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>

                                    </div>)}

                                </div>
                                <div className="tab-pane fade" id="metrics">
                                    <div className="profile_info">
                                        <div  style={!hasFish ? { "display": "none" } : {}}>
                                            <Line
                                                data={richnessData}
                                                legend={legend}
                                                options={{
                                                    annotation: {
                                                        annotations: line
                                                    },
                                                    responsive: true,
                                                    maintainAspectRatio: true,
                                                    scales: {
                                                        yAxes: [{
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 20,
                                                                labelString: '% Samples'
                                                            }
                                                        }],
                                                        xAxes: [{
                                                            scaleLabel: {
                                                                display: true,
                                                                fontSize: 20,
                                                                labelString: 'Fish Species Richness'
                                                            }
                                                        }],
                                                    }

                                                }}
                                            />
                                        </div>

                                    </div>

                                </div>
                                <div className="tab-pane fade" id="badge">
                                    <div className="profile_info">
                                        <div  style={(!hasFish || !badgeImg ) ? { "display": "none" } : {}}>
                                        <S3Image id="species_image" className="col-lg-12" imgKey={badgeImg} />
                                        </div>

                                    </div>

                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal" id="show_discription">
                <div className="modal-dialog modal-10001 modal_bg1">

                </div>
            </div>

            {showSideNav ? (<div id="mySidenav" className="sidenav" style={{ "width": "30%" }}>
                <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>&times;</a>
                <div className="modal_left mobile-overlow w-100">
                    <div class
                        ="p-l-36">
                        <span id="sic_name">{sicName}</span>
                    </div>
                    <div className="p-l-36">
                        <span id="com_name">{comName}</span>
                    </div>
                    <div className="row p-10">
                        <div className="col-lg-12"><S3Image id="species_image" className="col-lg-12" imgKey={spImg} /></div>

                    </div>
                    <div className="row p-10">
                        <div className="col-lg-12" >
                            <S3Image id="map_image" className="col-lg-12" imgKey={mapImg} /></div>
                    </div>
                    <div className="row p-10">
                        <div className="col-lg-12" id="des">{desc}</div>
                    </div>
                </div>
            </div>) : (<div />)}

        </section>


    );
}
